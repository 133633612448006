import Aderall30mgCapsulesx3 from "../assets/products-images/Aderall 250mg caps x 3.webp";
import Aderall30mgCapsulesx6 from "../assets/products-images/Aderall-30mg-caps-x6.webp";
import TwoOxycontin10mgAndoneVivanse70mg from "../assets/products-images/2-Oxycontin-10mg-tabs-&-Vivanse-70mg-caps.webp";
import TwoPercocet10mgAndOneAderral30mg from "../assets/products-images/2-Percocet-10mg-tabs-&-Aderall-30mg-caps.webp";
import Aderall25mgCapsules from "../assets/products-images/Aderall-25mg-caps.webp";
import TwoVicodin10mgAndOneAderall70mg from "../assets/products-images/2-vicodin-10mg-tabs-&-Aderall-70mg-caps.webp";
import OneOxycontin10mgTabsAndOneAderall30mgCapsPlusOnePercocet10mgTabs from "../assets/products-images/1-Oxycontin-10mg-tabs-&-Aderall-30mg-Caps-&-Percocet-10mg-tabs.webp";
import Aderall30mgCapsules from "../assets/products-images/Aderall-30mg-caps.webp";
import TwoOxycodone30mgTabsPlusAderall30mgCaps from "../assets/products-images/2-Oxycodone-Hydrochloride-tabs-&-Aderall-30mg-caps.webp";
import ThreAderralL15mgCaps from "../assets/products-images/Aderall-15mg-caps-x3.webp";
import SixAderall15mgCaps from "../assets/products-images/Aderall-15mg-caps-x6.webp";
import EightAderall15mgCaps from "../assets/products-images/Aderall-15mg-caps-x8.webp";
import ThreeAderall25mgCaps from "../assets/products-images/Aderall-25mg-caps-x3.webp";
import SixAderall25mgCaps from "../assets/products-images/Aderall-25mg-caps-x6.webp";
import EightAderall25mgCaps from "../assets/products-images/Aderall-25mg-caps-x8.webp";
import TwoOxxycontinAndOneAderallXR30mgCaps from "../assets/products-images/2-Oxycontin-10mg-Tabs-&-AderallXR-30mg-caps.webp";
import ThreeAderallXR30mg from "../assets/products-images/AderallXR-30mg-caps-x3.webp";
import SixAderall30XR30mg from "../assets/products-images/AderallXR-30mg-caps-x6.webp";
import ThreeAdipexP37mgCapsules from "../assets/products-images/Adipex-P-37.5mg-caps-x3.webp";
import SixAdipex37mgCapsules from "../assets/products-images/Adipex-P-37.5mg-caps-x6.webp";
import TenAdipex37mgCapsules from "../assets/products-images/Adipex-P-37.5mg-caps-x10.webp";
import AdipexP37mgCapsules from "../assets/products-images/Adipex-P-37.5mg-caps.webp";
import TenAderallXRCaps from "../assets/products-images/AderallXR-30mg-caps-x10.webp";
import AderallXR30mgCaps from "../assets/products-images/AderallXR-30mg-Caps.webp";
import TwoOxycontin10mgtabsAndAderall30mgCaps from "../assets/products-images/2-Oxycontin-10mg-tabs&Aderall-30mg-caps.webp";
import Vicodin10mgTabsAndPercocet10mgTabsAndAderall30mgCaps from "../assets/products-images/Vicodine-10mg-tabs+percocet10mg-tabs+aderall-30mg-tabs.webp";
import Vicodin10mgTabsAndVivanse70mgCapsAndPercocet10mgTabs from "../assets/products-images/Vicodin-10mg-tabs-+Vivanse-70mg-caps-+-Percocet-10mg-tabs.webp";
import Vicodin10mgTabsAndAderrall30mgCapsAndPercocet10mgTabs from "../assets/products-images/Vicodin-10mg-tabs-+Aderall-30mg-caps-+-Percocet-10mg-tabs.webp";
import Oxycontin10mgTabs from "../assets/products-images/Oxycontin-10mg-Tabs.webp";
import Oxycontin20mgTabs from "../assets/products-images/Oxycontin-20mg-Tabs.webp";
import SixFastin245mg from "../assets/products-images/Fastin-245mg-Caps-x6.webp";
import FourFastin245mg from "../assets/products-images/Fastin-245mg-Caps-x4.webp";
import Fastin245mgCaps from "../assets/products-images/Fastin-245mg-Caps.webp";
import TenIonamin30mgCaps from "../assets/products-images/Ionamin-30mg-caps-x10.webp";
import SixIonamin30mgCaps from "../assets/products-images/Ionamin-30mg-caps-x6.webp";
import ThreeIonamin30mgCaps from "../assets/products-images/Ionamin-30mg-caps-x3.webp";
import Vivanse50mgCaps from "../assets/products-images/Vivanse-50mg-caps.webp";
import Ionamin30mgCaps from "../assets/products-images/Ionamin-30mg-caps.webp";
import TwoOxycodone30mgTabsPlusAderallXR30mgCaps from "../assets/products-images/2-Oxycodone-30mg-tabs-&-AderallXR-30mg-caps.webp";
import Oxycontin10mgTabsPlusVivanse70mgCapsPlusVicodin10mgTabs from "../assets/products-images/Oxycontin-10mg+tabs+Vivanse-70mg-caps+Vicodin-10mg-tabs.webp";
import OxycodoneHydrochloride30mgTablets from "../assets/products-images/Oxycodone-Hydrochloride-30mg-Tabs.webp";
import TwoOxycodone30mgTabsPlusVivanse70mgCaps from "../assets/products-images/2-Oxycodone-30mg-tabs-&-Vivanse-70mg-caps.webp";
import Percocet10mgTabs from "../assets/products-images/Percocet-10mg-tabs.webp";
import TwoPercocet10mgTabsPlusVivanse70mgCaps from "../assets/products-images/2-Percocet-10mg-tabs-&-Vivanse-70mg-caps.webp";
import TwoPercocet10mgTabsPlusAderallXR30mgCaps from "../assets/products-images/2-Percocet-10mg-Tabs-&-AderallXR-30mg-Caps.webp";
import RedotexNF50mgCaps from "../assets/products-images/RedotexNF-50mg-caps.webp";
import Redotex50mgCaps from "../assets/products-images/Redotex-50mg-caps.webp";
import Soma350mgCaps from "../assets/products-images/Soma-350mg-caps.webp";
import ThreePackSoma350caps from "../assets/products-images/Soma-350mg-caps-x3.webp";
import SixPackSoma350caps from "../assets/products-images/Soma-350mg-caps-x6.webp";
import TenPackSoma350caps from "../assets/products-images/Soma-350mg-caps-x10.webp";
import TrazodoneHydrochloride100mgTabs from "../assets/products-images/traZODONE-Hydrochloride-100mg-tabs.webp";
import Vivanse30mgCaps from "../assets/products-images/Vivanse-30mg-caps.webp";
import Vivanse30mgCapsx3 from "../assets/products-images/Vivanse-30mg-caps-x3.webp";
import Vivanse30mgCapsx6 from "../assets/products-images/Vivanse-30mg-caps-x6.webp";
import Vivanse30mgCapsx10 from "../assets/products-images/VIvanse-30mg-caps-x10.webp";
import Vivanse50mgCapsx3 from "../assets/products-images/Vivanse-50mg-caps-x3.webp";
import Vivanse50mgCapsx6 from "../assets/products-images/Vivanse-50mg-caps-x6.webp";
import Vivanse50mgCapsx10 from "../assets/products-images/VIvanse-30mg-caps-x10.webp";
import Oxycontin10mgTabsPlusVivanse70mgCapsPlusPercocet10mgTabs from "../assets/products-images/Oxycontin10mg-caps+Vivanse70mgcaps-+Percocet10mg.webp";
import Vivanse70mgCapsx10 from "../assets/products-images/Vivanse-50mg-caps-x10.webp";
import Vivanse70mgCapsx3 from "../assets/products-images/Vivanse-70mg-caps-x3.webp";
import Oxycontin10mgTabsPlusAderall30mgCapsPlusVicodin10mgTabs from "../assets/products-images/Oxycontin-10mg-+Aderall30mg+Vicodin10mg.webp";
import Vicodin10mgx6Oxycodone30mgTabs from "../assets/products-images/Vicodin-10mg-Tabs-x6+Oxycodone-10mg-Tabs.webp";
import Vicodin10mgTabs from "../assets/products-images/Vicodin-10mg-tabs.webp";
import Oxycontin10mgPlusAderallXR30mgPlusVicodin10mg from "../assets/products-images/Oxycontin10mg-tabs-+AderallXR30mgcaps+Vicodin10mgtabs.webp";
import Vicodin10mgx2PlusVivance70mgCaps from "../assets/products-images/Vicodin10mgtabsx2+vivanse70mgcaps.webp";
import Vivanse70mgCaps from "../assets/products-images/Vivanse-70mg-caps.webp";
import Vivanse70mgCapsx6 from "../assets/products-images/Vivanse70mgcapsx6.webp";
import Xenical120mgCaps from "../assets/products-images/Xenical-120mg-caps.webp";
import Oxycontin10mgPlusAderallXRPlusPercocet10mg from "../assets/products-images/Oxycontin10mg+AderallXR+Percocet10mg.webp";
import Cialis20mgTabsx6 from "../assets/products-images/Cialis-20mg-x6.webp";
import Cialis20mgTabsx10 from "../assets/products-images/Cialis-20mg-tabs-x10.webp";
import Cialis20mgTabsx3 from "../assets/products-images/Cialis-20mg-tabs-x3.webp";
import Oxycontin10mgTabsx10 from "../assets/products-images/Oxycontin-10mg-tabs-x10.webp";
import Oxycontin20mgTabsx10 from "../assets/products-images/Oxycontin-20mg-tabs-x10.webp";
import Oxycontin10mgTabsx6 from "../assets/products-images/Oxycontin-10mg-tabs-x6.webp";
import Oxycontin10mgTabsx3 from "../assets/products-images/Oxycontin-10mg-tabs-x3.webp";
import Oxycontin20mgTabsx3 from "../assets/products-images/Oxycontin-20mg-tabs-x3.webp";
import Oxycontin20mgTabsx6 from "../assets/products-images/Oxycontin-20mg-tabs-x6.webp";
import Viagra100mgTabsx3 from "../assets/products-images/Viagra-100mg-tabs-x3.webp";
import Viagra100mgTabsx6 from "../assets/products-images/Viagra-100mg-tabs-x6.webp";
import Viagre100mgTabsx10 from "../assets/products-images/Viagra-100mg-caps-x10.webp";
import Nalbuphine from "../assets/products-images/Nalbuphine.jpg"
import Somatrope50UI from "../assets/products-images/Somatrope50.webp"
import MyAccountNav from "../../views/MyAccountNav";

const AmericanProductsList = [
  {
    id: "9ruv5i",
    title: "3-Pack Aderall 30mg Capsules",
    image: Aderall30mgCapsulesx3,
    description:
      "This combination medication is used to treat attention deficit hyperactivity disorder - ADHD. It works by changing the amounts of certain natural substances in the brain. Amphetamine/dextroamphetamine belongs to a class of drugs known as stimulants. It can help increase your ability to pay attention, stay focused on an activity, and control behavior problems. It may also help you to organize your tasks and improve listening skills.This drug is also used to treat a certain sleeping disorder (narcolepsy) to help you stay awake during the day. It should not be used to treat tiredness or to hold off sleep in people who do not have a sleep disorder.",
  },
  {
    id: "ermbm3",
    title: "6-Pack  Aderall 30mg Capsules",
    image: Aderall30mgCapsulesx6,
    description:
      "This combination medication is used to treat attention deficit hyperactivity disorder - ADHD. It works by changing the amounts of certain natural substances in the brain. Amphetamine/dextroamphetamine belongs to a class of drugs known as stimulants. It can help increase your ability to pay attention, stay focused on an activity, and control behavior problems. It may also help you to organize your tasks and improve listening skills.This drug is also used to treat a certain sleeping disorder (narcolepsy) to help you stay awake during the day. It should not be used to treat tiredness or to hold off sleep in people who do not have a sleep disorder.",
  },
  {
    id: "yq6uvq",
    title: "2-Pack Oxycontin 10mg Tablets +  1 Vivanse 70mg Capsules",
    image: TwoOxycontin10mgAndoneVivanse70mg,
    description: "2 Oxycontin 10mg Tablets + 1 Vivanse 70mg Capsules PACK",
  },
  {
    id: "s26fv4",
    title: "2-Pack Percocet 10mg*/325mg Tablets + 1 Aderall 30mg Capsules",
    image: TwoPercocet10mgAndOneAderral30mg,
    description:
      "2 Percocet 10mg*/325mg Tablets + 1 Aderall 30mg Capsules PACK",
  },
  {
    id: "ya80u2",
    title: "Aderall 25mg Capsules",
    image: Aderall25mgCapsules,
    description:
      "This combination medication is used to treat attention deficit hyperactivity disorder - ADHD. It works by changing the amounts of certain natural substances in the brain. Amphetamine/dextroamphetamine belongs to a class of drugs known as stimulants. It can help increase your ability to pay attention, stay focused on an activity, and control behavior problems. It may also help you to organize your tasks and improve listening skills.This drug is also used to treat a certain sleeping disorder (narcolepsy) to help you stay awake during the day. It should not be used to treat tiredness or to hold off sleep in people who do not have a sleep disorder.",
  },
  {
    id: "uzls5r",
    title: "2-Pack Vicodin 10mg*/325mg Tablets + 1 Aderall 30mg Capsules",
    image: TwoVicodin10mgAndOneAderall70mg,
    description: "2 Vicodin 10mg*/325mg Tablets + 1 Aderall 30mg Capsules PACK",
  },
  {
    id: "54yqud",
    title:
      "1 Oxycontin 10mg Tablets + 1 Aderall 30mg Capsules + 1 Percocet 10mg*/325mg Tablets",
    image: OneOxycontin10mgTabsAndOneAderall30mgCapsPlusOnePercocet10mgTabs,
    description:
      "1 Oxycontin 10mg Tablets+ 1 Aderall 30mg Capsules + 1 Percocet 10mg*/325mg Tablets PACK",
  },
  {
    id: "et1m2l",
    title: "Aderall 30mg Capsules",
    image: Aderall30mgCapsules,
    description:
      "This combination medication is used to treat attention deficit hyperactivity disorder - ADHD. It works by changing the amounts of certain natural substances in the brain. Amphetamine/dextroamphetamine belongs to a class of drugs known as stimulants. It can help increase your ability to pay attention, stay focused on an activity, and control behavior problems. It may also help you to organize your tasks and improve listening skills.This drug is also used to treat a certain sleeping disorder (narcolepsy) to help you stay awake during the day. It should not be used to treat tiredness or to hold off sleep in people who do not have a sleep disorder.",
  },
  {
    id: "qkl88p",
    title: "2-Pack Oxycodone Hydrochloride 30mg Tablets + Aderall 30mg Capsules",
    image: TwoOxycodone30mgTabsPlusAderall30mgCaps,
    description:
      "2 Oxycodone Hydrochloride 30mg Tablets + Aderall 30mg Capsules PACK",
  },
  {
    id: "ew55zz",
    title: "3-Pack Aderall 15mg Capsules",
    image: ThreAderralL15mgCaps,
    description: "3 Aderall 15mg Capusles PACK",
  },
  {
    id: "grelf3",
    title: "6-Pack Aderall 15mg Capsules",
    image: SixAderall15mgCaps,
    description: "6 Aderall 15mg Capsules PACK",
  },
  {
    id: "wal9pt",
    title: "8-Pack Aderall 15mg Capsules",
    image: EightAderall15mgCaps,
    description: "8 Aderall 15mg Capsules PACK",
  },
  {
    id: "56k2is",
    title: "3-Pack Aderall 25mg Capsules",
    image: ThreeAderall25mgCaps,
    description: "3 Aderall 25mg Capsules PACK",
  },
  {
    id: "2p3kbu",
    title: "6-Pack Aderall 25mg Capsules",
    image: SixAderall25mgCaps,
    description: "6 Aderall 25mg Capsules PACK",
  },
  {
    id: "aghxwq",
    title: "8-PackA Aderall 25mg Capsules",
    image: EightAderall25mgCaps,
    description: "8 Aderall 25mg Capsules PACK",
  },
  {
    id: "luxo5r",
    title: "2-Pack Oxycontin 10mg Tablets + 1 Aderall XR 30mg Capsules",
    image: TwoOxxycontinAndOneAderallXR30mgCaps,
    description: "2 Oxycontin 10mg Tablets + 1 Aderall 30mg Capsules PACK",
  },
  {
    id: "AXvsr2nP2EEA8f1",
    title: "Aderall-XR 30mg Capsules",
    image: AderallXR30mgCaps,
    description:
      "This combination medication is used to treat attention deficit hyperactivity disorder - ADHD. It works by changing the amounts of certain natural substances in the brain. Amphetamine/dextroamphetamine belongs to a class of drugs known as stimulants. It can help increase your ability to pay attention, stay focused on an activity, and control behavior problems. It may also help you to organize your tasks and improve listening skills.This drug is also used to treat a certain sleeping disorder (narcolepsy) to help you stay awake during the day. It should not be used to treat tiredness or to hold off sleep in people who do not have a sleep disorder.",
  },
  {
    id: "g4x5q1",
    title: "3-Pack Aderall-XR 30mg Capsules",
    image: ThreeAderallXR30mg,
    description: "3-Pack Aderall XR 30mg Capsules",
  },
  {
    id: "jqa02f",
    title: "6-Pack Aderall-XR 30mg Capsules",
    image: SixAderall30XR30mg,
    description: "6Pcs Aderall XR 30mg Capsules",
  },
  {
    id: "tU6Sjl7qfJuvXYS",
    title: "10-Pack Aderall-XR 30mg Capsules",
    image: TenAderallXRCaps,
    description: "10Pcs Aderall-XR 30mg Capsules",
  },

  {
    id: "koblv8",
    title: "3-Pack Adipex-P 37-5mg Capsules",
    image: ThreeAdipexP37mgCapsules,
    description: "3-Pack Adipex-P 37.5mg Capsules",
  },
  {
    id: "ub9drx",
    title: " 6-Pack Adipex-P 37.5mg Capsules",
    image: SixAdipex37mgCapsules,
    description: "6-Pack Adipex-P 37.5mg Capsules",
  },
  {
    id: "hz6tgh",
    title: "10-Pack Adipex-P 37.5mg Capsules",
    image: TenAdipex37mgCapsules,
    description: "10-Pack Adipex-P 37.5mg Capsules",
  },
  {
    id: "YcCxtw5SkWQaSqv",
    title: "Adipex-P 37.5mg Capsules",
    image: AdipexP37mgCapsules,
    description:
      "Phentermine is used with a doctor-approved exercise, behavior change, and reduced-calorie diet program to help you lose weight. It is used by certain overweight people, such as those who are obese or have weight-related medical problems. Losing weight and keeping it off can lessen the many health risks that come with obesity, including heart disease, diabetes, high blood pressure, and a shorter life.It is not known how this medication helps people to lose weight. It may work by decreasing your appetite, increasing the amount of energy used by your body, or by affecting certain parts of the brain. This medication is an appetite suppressant and belongs to a class of drugs called sympathomimetic amines.",
  },

  {
    id: "hLmvWQzwC9xKq7C",
    title: "2-Pack Oxycontin 10mg Tablets + 1 Aderall 30mg Capsules",
    image: TwoOxycontin10mgtabsAndAderall30mgCaps,
    description: "2-Pack Oxycontin 10mg Tablets + Aderall 30mg Capsules",
  },
  {
    id: "yjT3nGdPYjI7hnC",
    title:
      "Vicodin 10mg/325mg Tablets + Percocet 10mg/325mg Tablets + Aderall 30mg Capsules",
    image: Vicodin10mgTabsAndPercocet10mgTabsAndAderall30mgCaps,
    description: "Vicodin + Percocet + Aderall",
  },
  {
    id: "NYVvTCikj5DwwzM",
    title:
      "Vicodin 10mg/325mg Tablets +  Vivanse 70mg Capsules + Percocet 10mg/325mg Tablets",
    image: Vicodin10mgTabsAndVivanse70mgCapsAndPercocet10mgTabs,
    description:
      "Vicodin 10mg/325mg Tablets +  Vivanse 70mg Capsules + Percocet 10mg/325mg Tablets",
  },
  {
    id: "cSxpx0UU20X1LfU",
    title:
      "Vicodin 10/325mg Tablets + Aderall 30mg Capsules + Percocet 10/325mg Tablets",
    image: Vicodin10mgTabsAndAderrall30mgCapsAndPercocet10mgTabs,
    description:
      "Vicodin 10/325mg Tablets + Aderall 30mg Capsules + Percocet 10/325mg Tablets",
  },
  {
    id: "6eQdm0QYOeX22hz",
    title: "Oxycontin 10mg Tablets",
    image: Oxycontin10mgTabs,
    description:
      "This medication is used to help relieve severe ongoing pain (such as due to cancer). Oxycodone belongs to a class of drugs known as opioid analgesics. It works in the brain to change how your body feels and responds to pain.The higher strengths of this drug (more than 40 milligrams per tablet) should be used only if you have been regularly taking moderate to large amounts of an opioid pain medication. These strengths may cause overdose (even death) if taken by a person who has not been regularly taking opioids.Do not use the extended-release form of oxycodone to relieve pain that is mild or that will go away in a few days. This medication is not for occasional use.",
  },
  {
    id: "9t93dqEfA2zUnAM",
    title: "Oxycontin 20mg Tablets",
    image: Oxycontin20mgTabs,
    description:
      "This medication is used to help relieve severe ongoing pain (such as due to cancer). Oxycodone belongs to a class of drugs known as opioid analgesics. It works in the brain to change how your body feels and responds to pain.The higher strengths of this drug (more than 40 milligrams per tablet) should be used only if you have been regularly taking moderate to large amounts of an opioid pain medication. These strengths may cause overdose (even death) if taken by a person who has not been regularly taking opioids.Do not use the extended-release form of oxycodone to relieve pain that is mild or that will go away in a few days. This medication is not for occasional use.",
  },
  {
    id: "UMw1DNpT5hADqfI",
    title: "6-Pack Fastin 245mg Capsules",
    image: SixFastin245mg,
    description: "6Pcs Fastin 245mg Capsules",
  },
  {
    id: "YrnBlUGN9ttg9iE",
    title: "4-Pack Fastin 245mg Capsules",
    image: FourFastin245mg,
    description: "4-pack Fastin245mg Capsules",
  },
  {
    id: "NPBFopD6avBVREA",
    title: "Fastin 245mg Capsules",
    image: Fastin245mgCaps,
    description:
      "Phenethylamine (PEA) is a chemical with stimulant effects. It's found naturally in plants, bacteria, fungi, and animals. It can also be made in a lab. Phenethylamine stimulates the body to make certain chemicals that play a role in brain chemistry. It is similar to the drug amphetamine and may cause similar side effects. People use phenethylamine for athletic performance, depression, obesity, and other conditions, but there is no good scientific evidence to support these uses.",
  },
  {
    id: "KmgH7VCwuchpH9n",
    title: "3-Pack Ionamin 30mg Capsules",
    image: ThreeIonamin30mgCaps,
    description: "3Pcs Ionamin 30mg Capsules",
  },
  {
    id: "cZePdAiBLFMNGvS",
    title: "10-Pack Ionamin 30mg Capsules",
    image: TenIonamin30mgCaps,
    description: "10Pcs Ionamin 30mg Capsules",
  },
  {
    id: "0IXXxDDSH77j6Jd",
    title: "6-Pack Ionamin 30mg Capsules",
    image: SixIonamin30mgCaps,
    description: "6Pcs Ionamin 30mg Capsules",
  },
  {
    id: "L1Od8daCvpk9twX",
    title: "Ionamin 30mg Capsules",
    image: Ionamin30mgCaps,
    description:
      "Phentermine is used with a doctor-approved exercise, behavior change, and reduced-calorie diet program to help you lose weight. It is used by certain overweight people, such as those who are obese or have weight-related medical problems. Losing weight and keeping it off can lessen the many health risks that come with obesity, including heart disease, diabetes, high blood pressure, and a shorter life.It is not known how this medication helps people to lose weight. It may work by decreasing your appetite, increasing the amount of energy used by your body, or by affecting certain parts of the brain. This medication is an appetite suppressant and belongs to a class of drugs called sympathomimetic amines.",
  },
  {
    id: "FHQyn9k6tbHk97J",
    title: "Vivanse 50mg Capsules",
    image: Vivanse50mgCaps,
    description:
      "Lisdexamfetamine is used to treat attention deficit hyperactivity disorder (ADHD) as part of a total treatment plan, including psychological, social, and other treatments. It may help to increase the ability to pay attention, stay focused, and stop fidgeting. Lisdexamfetamine may also be used to treat binge eating disorder (BED). It may help to reduce the number of binge eating days.This medication is a stimulant. It is thought to work by restoring the balance of certain natural chemicals (neurotransmitters) in the brain.This medication is not recommended for use for weight loss due to the risk of serious side effects.",
  },
  {
    id: "ksAVPAOpyeFwng3",
    title:
      "2-Pack Oxycodone Hydrochloride 30mg Tablets + AderallXR 30mg Capsules",
    image: TwoOxycodone30mgTabsPlusAderallXR30mgCaps,
    description: "2-Pack Oxycodone 30mg Tabs + AderallXR 30mg Capsules ",
  },
  {
    id: "UAjRD8mAyxfNvec",
    title:
      "Oxycontin 10mg Tablets + Vivanse 70mg Capsules + Vicodin 10mg/325 Tablets",
    image: Oxycontin10mgTabsPlusVivanse70mgCapsPlusVicodin10mgTabs,
    description: "3Pcs",
  },
  {
    id: "v1LiU1mFshbabTh",
    title: "Oxycodone Hydrochloride 30mg Tablets",
    image: OxycodoneHydrochloride30mgTablets,
    description:
      "Oxycodone is used to relieve pain severe enough to require opioid treatment and when other pain medicines did not work well enough or cannot be tolerated. It belongs to the group of medicines called narcotic analgesics (pain medicines). Oxycodone acts on the central nervous system (CNS) to relieve pain.",
  },
  {
    id: "IfW54hBfjCmZtgy",
    title:
      "2-Pack Oxycodone Hydrochloride 30mg Tablets + Vivanse 70mg Capsules",
    image: TwoOxycodone30mgTabsPlusVivanse70mgCaps,
    description: "3PCs",
  },
  {
    id: "lVvvwJ3z0eJXy6f",
    title: "Percocet 10*/325mg Tablets",
    image: Percocet10mgTabs,
    description:
      "Oxycodone is used to relieve pain severe enough to require opioid treatment and when other pain medicines did not work well enough or cannot be tolerated. It belongs to the group of medicines called narcotic analgesics (pain medicines). Oxycodone acts on the central nervous system (CNS) to relieve pain.",
  },
  {
    id: "3ZyjqIyePLBuzo6",
    title: "2-Pack Percocet 10*/325mg Tablet + Vivanse 70mg Capsules",
    image: TwoPercocet10mgTabsPlusVivanse70mgCaps,
    description: "3PCs",
  },
  {
    id: "PmVJk9m3obNBQkJ",
    title: "2-Pack Percocet 10*/325mg Tablets + AderallXR 30mg Capsules",
    image: TwoPercocet10mgTabsPlusAderallXR30mgCaps,
    description: "3PCs",
  },

  {
    id: "9w1WrgS7EnGRZCO",
    title: "Redotex-NF 50mg Capsules",
    image: RedotexNF50mgCaps,
    description: "Redotex-NF-50MG Capsules",
  },
  {
    id: "dlj0p55qc3fa4ea",
    title: "Redotex 50mg Capsules",
    image: Redotex50mgCaps,
    description: "REDOTEX 50MG CAPS",
  },

  {
    id: "iZnUzh1ctucBJYz",
    title: "Soma 350mg Capsules",
    image: Soma350mgCaps,
    description: "Soma",
  },
  {
    id: "QZoXqte4WAiYH9B",
    title: "3-Pack Soma 350mg Capsules",
    image: ThreePackSoma350caps,
    description: "3PCs",
  },
  {
    id: "WNItGKN56tUKIq2",
    title: "6-Pack Soma 350mg Capsules",
    image: SixPackSoma350caps,
    description: "6Pcs",
  },
  {
    id: "q82zBqIu2ryq6pZ",
    title: "10-Pack Soma 350mg Capsules",
    image: TenPackSoma350caps,
    description: "10Pcs",
  },

  {
    id: "xKsVgCeZyGiWtDl",
    title: "traZODONE Hydrochloride 100mg Tablets",
    image: TrazodoneHydrochloride100mgTabs,
    description: "Trazodone100mgtabs",
  },
  {
    id: "P874pzSnECoK1kM",
    title: "Vivanse 30mg Capsules",
    image: Vivanse30mgCaps,
    description: "Vivanse30mgCaps",
  },
  {
    id: "KovotYFSL6n383W",
    title: "3-Pack Vivanse 30mg Capsules",
    image: Vivanse30mgCapsx3,
    description: "3Pcs",
  },
  {
    id: "gmmiJT4gqQ5xoN2",
    title: "6-Pack Vivanse 30mg Capsules",
    image: Vivanse30mgCapsx6,
    description: "6PCs",
  },
  {
    id: "vNHWyQfnuXHlZvX",
    title: "10-Pack Vivanse 30mg Capsules",
    image: Vivanse30mgCapsx10,
    description: "10PCs",
  },
  {
    id: "vvkZgEwqhxuPIgW",
    title: "3-Pack Vivanse 50mg Capsules",
    image: Vivanse50mgCapsx3,
    description: "3PCs",
  },
  {
    id: "IYV5gzCJvUohT98",
    title: "6-Pack Vivanse 50mg Capsules",
    image: Vivanse50mgCapsx6,
    description: "6PCs",
  },
  {
    id: "x7aiI7MQnqLNMIo",
    title: "10-Pack Vivanse 50mg Capsules",
    image: Vivanse50mgCapsx10,
    description: "10PCs",
  },
  {
    id: "2v6UbNevXOJuh6J",
    title:
      "Oxycontin 10mg Tablets + Vivanse 70mg Capsules + Percocet 10mg*/325gm Tablets",
    image: Oxycontin10mgTabsPlusVivanse70mgCapsPlusPercocet10mgTabs,
  },
  {
    id: "b3wzOof5Bpf4FqX",
    title: "10-Pack Vivanse 70mg Capsules",
    image: Vivanse70mgCapsx10,
    description: "10PCs",
  },
  {
    id: "mvorZ0yfVSIvH9J",
    title: "3-Pack Vivanse 70mg Capsules",
    image: Vivanse70mgCapsx3,
    description: "3PCs",
  },
  {
    id: "7a2hqAGNLRGNm38",
    title:
      "Oxycontin 10mg Tablets + Aderall 30mg Capsules +  Vicodin 10mg/325mg Tablets ",
    image: Oxycontin10mgTabsPlusAderall30mgCapsPlusVicodin10mgTabs,
    description: Oxycontin10mgTabsPlusAderall30mgCapsPlusVicodin10mgTabs,
  },
  {
    id: "Ng2NX2xB1NXirLq",
    title:
      "6-Pack Vicodin 10mg/325mg Tablets + Oxycodone Hydrochloride 30mg Tablets",
    image: Vicodin10mgx6Oxycodone30mgTabs,
    description: "7PCs",
  },
  {
    id: "RrgwgjiHSvduYkj",
    title: "Vicodin 10mg/325mg Tablets",
    image: Vicodin10mgTabs,
  },
  {
    id: "GRDImloCyWUDI5d",
    title:
      "Oxycontin 10mg Tablets + AderallXR 30mg Capsules + Vicodin 10mg/325mg Tablets",
    image: Oxycontin10mgPlusAderallXR30mgPlusVicodin10mg,
    description: "3PCs",
  },
  {
    id: "pSvh0s78Pme3JlB",
    title: "2-Pack Vicodin 10mg/325mg Tablets + Vivanse 70mg Capsules",
    image: Vicodin10mgx2PlusVivance70mgCaps,
    description: "3PCs",
  },
  {
    id: "VLoQygPRAKBSGl3",
    title: "Vivanse 70mg Capsules",
    image: Vivanse70mgCaps,
  },
  {
    id: "2bhP1vNgZBYlQ3C",
    title: "6-Pack Vivanse 70mg Capsules",
    image: Vivanse70mgCapsx6,
    description: "6PCs",
  },
  {
    id: "ua2v9oTXDAMhWGj",
    title: "Xenical 120mg Capsules",
    image: Xenical120mgCaps,
  },
  {
    id: "LztngUAen6Ux9ho",
    title:
      "Oxycontin 10mg Tablets + AderallXR 30mg Capsules + Percocet 10mg/325 Tablets",
    image: Oxycontin10mgPlusAderallXRPlusPercocet10mg,
  },
  {
    id: "6Gr0l002HTYqLHa",
    title: "6-Pack Cialis 20mg Tablets",
    image: Cialis20mgTabsx6,
    description: "6PCs",
  },
  {
    id: "oDDOwAhpMDqamtS0",
    title: "10-Pack Cialis 20mg Tablets",
    image: Cialis20mgTabsx10,
    description: "10PCs",
  },
  {
    id: "7kD8OIsqAIxYZz7",
    title: "3-Pack Cialis 20mg Tablets",
    image: Cialis20mgTabsx3,
    description: "3PCs",
  },
  {
    id: "b30QQt6LBte4ij2",
    title: "10-Pack Oxycontin 10mg Tablets",
    image: Oxycontin10mgTabsx10,
    description: "10PCs",
  },
  {
    id: "b30878kl09asDdfg",
    title: "10-Pack Oxycontin 20mg Tablets",
    image: Oxycontin20mgTabsx10,
    description: "10PCs",
  },
  {
    id: "ceKxb7x7BUoJJi3",
    title: "6-Pack Oxycontin 10mg Tablets",
    image: Oxycontin10mgTabsx6,
    description: "6PCs",
  },
  {
    id: "9jxjZdwnA5hTC5O",
    title: "3-Pack Oxycontin 10mg Tablets",
    image: Oxycontin10mgTabsx3,
    description: "3PCs",
  },
  {
    id: "lVOwZITh7soyQvI",
    title: "3-Pack Oxycontin 20mg Tablets",
    image: Oxycontin20mgTabsx3,
    description: "3PCs",
  },
  {
    id: "Grrxq0k7hcHNkdi",
    title: "6-Pack Oxycontin 20mg Tablets",
    image: Oxycontin20mgTabsx6,
    description: "6PCs",
  },
  {
    id: "eIiYMNKxATgWhz6",
    title: "3-Pack Viagra 100mg Tablets",
    image: Viagra100mgTabsx3,
    description: "3PCs",
  },
  {
    id: "qZkHun57j0QxWe9",
    title: "6-Pack Viagra 100mg Tablets",
    image: Viagra100mgTabsx6,
    description: "6PCs",
  },
  {
    id: "57ktuRYORvhZEYy",
    title: "10-Pack Viagra 100mg Tablets",
    image: Viagre100mgTabsx10,
    description: "10PCs",
  },{
    id: "IcKRTNW7w7f6Mx7",
    title: "Nalbuphine HCL Injection 20mg/ml",
    image: Nalbuphine,
  },
  {
    id: "1mf1DAAA2qPDAfC",
    title: "Somatrope 50-UI",
    image: Somatrope50UI,
  }
];

function AmericanProducts() {
  return (<div>
    <MyAccountNav/>
    <div className="container">
      <div className="product-container">
        <div className="row">
          {AmericanProductsList.map((pack) => (
            <div className="col-md-4 gy-4 " key={pack.id}>
              <div>
                <img src={pack.image} alt="img" className="product-image" />
                <p className="product-title fw-bold">{pack.title}</p>
              </div>
            </div>
          ))}
        </div>
      </div>
    </div>
    </div>
  );
}

export default AmericanProducts;
