import NurseImage from "../assets/Doctor.png";
import PrivacyPolicyIcon from "../assets/icons/customer-protection-privacy-icon.png";
import CompanyMissionIcon from "../assets/icons/company-mission-icon.png";
import OurPhilosophyIcon from "../assets/icons/our-philosophy-icon.png";
import OurService from "../assets/icons/our-service-icon.png";
import PharmacyImage from "../assets/our-service-imagen.png";
import "../pages/about-us.css";

function About() {
  
  return (
    <div className="container">
      <div className="about-us_items">
        <div className="about-us_text">
          <h1 className="display-1 fw-bold">OUR COMPANY</h1>
          <p className="lead">
            <span className="fw-bold">Duty Free Supply House</span> the place
            where we care about your health. We offer brand quality original
            products to our Pharmacies Owners, so they can supply correct
            medicine to their customers.
          </p>
        </div>
        <div>
          <img src={NurseImage} alt="" id="nurse-image" />
        </div>
      </div>
      <div className="about-us_items">
        <div className="about-us_text">
          <img src={PrivacyPolicyIcon} alt="" id="privacy-policy-icon" />
          <div className="section-info">
            <h2 className="display-4 fw-bold">CUSTOMER PROTECTION POLICY</h2>
            <p className="lead">
              At
              <span> Duty Free Supply House</span>, we prioritize customer
              protection by adhering to strict quality control measures,
              ensuring all products are stored and handled in compliance with
              industry regulations. We guarantee the authenticity and integrity
              of our products through rigorous testing and verification
              processes to safeguard against counterfeits. Our customer
              protection policy also includes quick response to any concerns or
              issues, with a dedicated team ready to address any queries
              promptly and efficiently. Your trust and safety are paramount to
              us, and we are committed to providing you with peace of mind when
              sourcing pharmaceutical supplies from us.
            </p>
          </div>
        </div>
      </div>

      <div className="about-us_items">
        <div className="about-us_text">
          <img src={CompanyMissionIcon} alt="" id="company-mission-icon" />
          <div className="section-info">
            <h2 className="display-4 fw-bold">COMPANY MISSION</h2>
            <p className="lead">
              At<span> Duty Free Supply House</span> We are committed to
              revolutionizing healthcare by providing seamless access to
              essential medications and supplies worldwide. With a vision to
              improve patient outcomes and optimize healthcare delivery, we
              strive to be a trusted partner in meeting the evolving needs of
              healthcare professionals and patients. Our dedication to
              innovation, quality, and personalized service ensures we are at
              the forefront of shaping the future of healthcare. Join us in our
              mission to redefine the standard of pharmaceutical supply and make
              a lasting impact on global health.
            </p>
          </div>
        </div>
      </div>

      <div className="about-us_items">
        <div>
          <img src={PharmacyImage} alt="" id="pharmacist-checking-medicines" />
        </div>

        <div className="about-us_text">
          <img src={OurPhilosophyIcon} alt="" id="our-philosophy-icon" />

          <h2 className="display-4 fw-bold">OUR PHILOSOPHY</h2>
          <p className="lead">
            At<span> Duty Free Supply House</span> our philosophy revolves
            around delivering high-quality and affordable products to meet the
            diverse needs of our clients. We are committed to fostering strong
            relationships with both suppliers and customers, ensuring
            transparency, reliability, and efficiency in every transaction. Our
            team is dedicated to providing exceptional customer service and
            continuously seeking innovative solutions to enhance the healthcare
            industry. With a focus on integrity, sustainability, and excellence,
            we strive to be a trusted partner in delivering essential
            pharmaceutical products to improve the lives of individuals
            worldwide.
          </p>
        </div>
      </div>
      <div className="about-us_items">
        <div className="about-us-text">
          <img src={OurService} alt="" id="our-service-icon" />
          <div className="section-info">
            <h2 className="display-4 fw-bold">OUR SERVICE</h2>
            <p className="lead">
              At<span> Duty Free Supply House</span> we prioritize customer
              protection by adhering to strict quality control measures,
              ensuring all products are stored and handled in compliance with
              industry regulations. We guarantee the authenticity and integrity
              of our products through rigorous testing and verification
              processes to safeguard against counterfeits. Our customer
              protection policy also includes quick response to any concerns or
              issues, with a dedicated team ready to address any queries
              promptly and efficiently. Your trust and safety are paramount to
              us, and we are committed to providing you with peace of mind when
              sourcing pharmaceutical supplies from us.
            </p>
          </div>
        </div>
      </div>
    </div>
  );
}

export default About;
