import React from "react";
import { BsQrCodeScan } from "react-icons/bs";
import { BsSearch } from "react-icons/bs";
import { BsShieldCheck } from "react-icons/bs";
import "./banner3.css";

const Banner3 = () => {
  return (
    <div className="Banner3-container bg-primary">
      <div className="Banner3-section">
        <BsQrCodeScan id="scan-icon" />
        <p>Brand and generic products</p>
        <span>
          European Medicines Agency and American F.D.A. Approved RX medication
        </span>
      </div>
      <div className="Banner3-section">
        <BsSearch id="search-icon" />
        <p>Hard to Find Special Products</p>
        <span>
          If You don’t see the product in our list, please send us an email
        </span>
      </div>
      <div className="Banner3-section">
        <BsShieldCheck id="shield-icon" />
        <p>#1 Most Reliable Source</p>
        <span>100% Confidential</span>
      </div>
    </div>
  );
};

export default Banner3;
