import React from "react";
import MyAccountNav from "./MyAccountNav";

function MyAccount() {
  return (
    <div className="MyAccount">
      <MyAccountNav />
      <div className="My-account-banner"></div>
    </div>
  );
}

export default MyAccount;
