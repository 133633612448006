import { Link } from "react-router-dom";
import products from "../product-list";
import "./Products.css";

function Products() {
  return (
    <div>
      <div className="category">
      <h1>PRODUCTS</h1>
      </div>
    <div className="container">
      
      <div className="product-container">
        <div className="row">
          {products.map(product=> (
            <div className="col-md-4 gy-3 " key={product.id}>
              <div>
                <img className="product-image" src={product.image} alt="image"/>
                <p className="product-title">{product.title}</p>
                <Link to={`/Products/${product.id}`} className="btn btn-outline-secondary bg-primary text-light border-0 rounded link-light">
                  See More
                </Link>
              </div>
            </div>
          ))}
        </div>
      </div>
    </div>
    </div>
  );
}

export default Products;
