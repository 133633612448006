import Amitriptyline from "./assets/products-images/Amitriptyline-HCL-25mg-1.jpg";
import Citalopram from "./assets/products-images/Citalopram-tablets-20mg.jpg";
import Carisoprodol from "./assets/products-images/Carisoprodol-350mg.jpg";
import Famotidine40mg from "./assets/products-images/famotidine-40mg-tablets.jpg";
import Lisinopril from "./assets/products-images/Lisinopril 20mg.jpg";
import AmoxicilinCapsule from "./assets/products-images/Amoxicilin-Capsules 500g.jpg";
import Flexeryl from "./assets/products-images/Flexeryl 10mg Capsule.jpg";
import Meloxicam from "./assets/products-images/Meloxicam-15mg.jpg";
import Paroxetine from "./assets/products-images/paroxetine-20mg-tablets.jpg";
import Quinapril from "./assets/products-images/quinapril-20mg-tablets.jpg";
import Terazosin from "./assets/products-images/terazosin-2mg-capsules.jpg";
import Terazosin5mg from "./assets/products-images/terazosin-5mg-capsules.jpg";
import TriamtereAndHidrochlorotiazide from "./assets/products-images/triamterene-and-hydrochlorotiazide-tablets-3.27-25.jpg";
import TriamtereAndHidrochlorotiazide75 from "./assets/products-images/triamterene-and-hydrochlorotiazide-tablets-75mg-50mg.jpg"
import Niacin500mg from "./assets/products-images/niacin-500m-capsules.jpg";
import Warfarin from "./assets/products-images/warfarin-sodium-5mg-tablets.jpg";
import Xenical from "./assets/products-images/xenical-120mg-capsules.jpg";
import Mynocycline from "./assets/products-images/mynocycline-hydrocloride-100mg-tabs.jpg";
import Mirtazapine from "./assets/products-images/mirtazapine-15mg-tablets.jpg";
import Quinapril40mg from "./assets/products-images/quinapril-40mg-tablets.jpg";
import TrazodoneHydrochloride50mg from "./assets/products-images/trazodona-hydrochloride-50mg-tablets.jpg";
import Verapamil240mg from "./assets/products-images/verapamil-hydrochloride-240mg.jpg";
import Amitriptyline50mg from "./assets/products-images/Amitryptile-50mg-Tablets.jpg";
import BupropionHydroclodride150mg from "./assets/products-images/bupropion-hydrochloride-150mg-tablets.jpg";
import CyclobenzaprineHydrochloride10mg from "./assets/products-images/cyclobenzaprine-hydrochloride-10mg-tabs.jpg";
import DonepexyHydrochloride10mg from "./assets/products-images/donepexyl-hydrochloride-10mg-tabs.jpg";
import Famotidine20mg from "./assets/products-images/Famotidine-20mg.jpg";
import Ciprofloxacin500mg from "./assets/products-images/ciprofloxacin-500mg-tablets.jpg";
import Citalopram40mgTabs from "./assets/products-images/citalopram-40mg-tabs.jpg";
import Fluoxetina10mgCaps from "./assets/products-images/fluoxetina-10mg-caps.jpg";
import Atorvastin40mgTabs from "./assets/products-images/atorvastatin-calcium-40mg-tabs.jpg";
import HydroOXYzineHydrochloride25mgTabs from "./assets/products-images/hidroOxyzine-hydrochloride-25mg-tabs.jpg";
import LisinoprilAndhydroclorotiazide20m from "./assets/products-images/lisinopril-and-hydrochlorotiazide-20mg12.5.jpg";
import LithiumCarbonate300mg from "./assets/products-images/lithium-carbonate-300mg-caps.jpg";
import Lisinopril40mgTabs from "./assets/products-images/lisinopril-40mg-tabs.jpg";
import MynociclineHydrocloride50mgCaps from "./assets/products-images/minocycline-hydrocloride- 50mg-caps.jpg";
import MynociclineHydrocloride100mgCaps from "./assets/products-images/mynocycline-hydrocloride-100mg-tabs.jpg";
import Cialis20mgTabs from "./assets/products-images/Cialis-20mg-tabs.webp";
import Buspirone10mgTabs from "./assets/products-images/busPIRone-hidrochloride-10mg-Tabs.webp";
import Ramipril10mgCaps from "./assets/products-images/Ramipril-10mg-caps.webp";
import Ropinirole1mgTabs from "./assets/products-images/rOPINIRole-1mg-Tablets.webp";
import SulfamethoxazoleAndTrimethoprim800mg600mgTabs from "./assets/products-images/Sulfamethoxazole-and-Trimethoprim-800mg-600mg-tabs.webp";
import Viagra100mgTabs from "./assets/products-images/Viagra-100mg-tabs.webp";
import VitaminDCaps from "./assets/products-images/VitaminD-1.25mg.webp";

const products = [
  {
    id: "QinN6j0yUuh0nj6",
    title: "Amitriptyline 25mg Tablets",
    image: Amitriptyline,
    description:
      "Amitriptyline HCl is an antidepressant with sedative effects. Its mechanism of action in man is not known. It is not a monoamine oxidase inhibitor and it does not act primarily by stimulation of the central nervous system. Amitriptyline inhibits the membrane pump mechanism responsible for uptake of norepinephrine and serotonin in adrenergic and serotonergic neurons. Pharmacologically, this action may potentiate or prolong neuronal activity since reuptake of these biogenic amines is important physiologically in terminating transmitting activity. This interference with reuptake of norepinephrine and/or serotonin is believed by some to underlie the antidepressant activity of amitriptyline",
  },
  {
    id: 2,
    title: "Citalopram 20mg Tablets",
    image: Citalopram,
    description:
      "Citalopram hydrobromide is a selective serotonin reuptake inhibitor. The primary FDA-approved clinical use for citalopram hydrobromide is for treating depression in adults. Off-label uses include alcohol use disorder, coronary arteriosclerosis, obsessive-compulsive disorder, panic disorder, postmenopausal flushing, and premenstrual dysphoric disorder. Citalopram is listed in the WHO model list of essential medicines to treat depressive disorders. Citalopram hydrobromide exerts its antidepressant action by potentiating serotonergic activity in the central nervous system. Multiple studies confirmed that citalopram hydrobromide is a selective serotonin reuptake inhibitor (SSRI) that has minimal effects on norepinephrine and dopamine neuronal reuptake. This activity reviews the indications, mechanism of action, administration, contraindications, monitoring, toxicity associated with citalopram, and the interprofessional team's role in caring for patients with conditions treated with citalopram.",
  },
  {
    id: 3,
    title: "Carisoprodol 350mg Tablets",
    image: Carisoprodol,
    description:
      "Carisoprodol is an FDA-approved drug indicated for the relief of discomfort associated with acute, painful musculoskeletal conditions. Although marketed as a muscle relaxant, the parent compound is actually in the tranquilizer class of medications, with its primary metabolite meprobamate considered actually to be a benzodiazepine. This activity discusses the indications, mechanism of action, and contraindications for carisoprodol as a valuable agent to relax muscles after strains, sprains, and muscle injuries. This medication is intended to be used together with rest, physical therapy, and other measures. This activity highlights the mechanism of action, the potential benefits, the side effect profile, and other key factors (e.g., dosing, pharmacodynamics, pharmacokinetics, monitoring, relevant interactions) pertinent for healthcare professionals in managing patients with myofascial pain symptoms and related conditions.",
  },
  {
    id: 5,
    title: "Famotidine 40gmTablets",
    image: Famotidine40mg,
    description:
      "Famotidine decreases the production of stomach acid, and its pharmacologic activity is used in the treatment of acid-related gastrointestinal conditions. Famotidine is available both by prescription and over-the-counter (OTC). It is FDA approved and available through prescription for the treatment of duodenal ulcer, gastric ulcer, and gastroesophageal reflux disease (GERD) in adults and children, with a further indication for treatment of pathological hypersecretory conditions in adults. Famotidine is also FDA approved for over-the-counter treatment and prevention of heartburn due to gastroesophageal reflux in adults and pediatrics. It also has off-label uses. This activity outlines the indications, mechanism of action, methods of administration, important adverse effects, contraindications, monitoring, and toxicity of famotidine, so providers can direct patient therapy as needed to achieve positive outcomes.",
  },
  {
    id: 6,
    title: "Lisinopril 20mg  Tablets",
    image: Lisinopril,
    description:
      "Lisinopril is classified as an angiotensin-converting enzyme inhibitor and has been available for nearly three decades. Lisinopril has some key features that make it different from enalapril and captopril; 1) it has a long half-life, 2) it is hydrophilic, and 3) it is not broken down by the liver. Lisinopril is a competitive inhibitor of angiotensin-converting enzyme (ACE) and prevents the conversion of angiotensin I to angiotensin II, which is a potent vasoconstrictor. This activity covers lisinopril, including mechanism of action, pharmacology, adverse event profiles, eligible patient populations, monitoring, and highlights the role of the interprofessional team in the management of hypertension with lisinopril.",
  },
  {
    id: 7,
    title: "Amoxicilin 500mg Capsules",
    image: AmoxicilinCapsule,
    description:
      "Amoxicillin is a widely utilized beta-lactam antimicrobial drug approved by the U.S. Food and Drug Administration (FDA) for use in the primary care setting. Amoxicillin is an aminopenicillin created by adding an extra amino group to penicillin to battle antibiotic resistance. This drug is indicated for the treatment of infections caused by susceptible isolates of selected bacteria, specifically those that are beta-lactamase–negative, including ear, nose, and throat infections, Helicobacter pylori eradication, lower respiratory and urinary tract infections, acute bacterial sinusitis, and skin and structure infections. Amoxicillin is effective against a wide range of gram-positive bacteria, offering additional coverage against some gram-negative organisms compared to penicillin. Amoxicillin's spectrum of activity includes coverage against Streptococcus species, with heightened efficacy against Listeria monocytogenes and Enterococcus spp. Furthermore, amoxicillin also demonstrates effectiveness against Haemophilus influenzae, select Escherichia coli strains, Actinomyces spp., Clostridium species, Salmonella spp., Shigella spp., and Corynebacteria spp. This activity delves into the indications, mechanism of action, administration, contraindications, and adverse event profiles associated with amoxicillin. This activity equips clinicians with a comprehensive understanding of amoxicillin to optimally enhance their ability to manage infectious diseases in patients.",
  },
  {
    id: 8,
    title: "Flexeryl 10mg Capsules",
    image: Flexeryl,
    description:
      "Cyclobenzaprine is FDA-approved as an adjunct to rest for the treatment of muscle spasms associated with acute, painful musculoskeletal conditions. Cyclobenzaprine is a part of a group of medications referred to as cyclical antidepressants. Cyclobenzaprine is a tricyclic amine salt that works in the central nervous system (CNS) as a depressant that reduces muscle hyperactivity. These cyclical antidepressants have roles in treating depression, neuropathic pain, migraine prophylaxis, attention deficit hyperactive disorder, and potential muscle relaxation properties. In addition, this activity reviews the mechanism of action, adverse event profile, toxicity, dosing, pharmacodynamics, and monitoring of cyclobenzaprine for members of the interprofessional team who will potentially prescribe or encounter patients taking cyclobenzaprine.",
  },
  {
    id: 9,
    title: "Meloxicam 15mg Tablets",
    image: Meloxicam,
    description:
      "nonsteroidal anti-inflammatory drug (NSAID). It works by reducing hormones that cause pain, fever, and inflammation in the body. Mobic is used to relieve pain, tenderness, swelling, and stiffness caused by osteoarthritis (arthritis caused by a breakdown of the lining of the joints) and rheumatoid arthritis (arthritis caused by swelling of the lining of the joints). Mobic is also used to relieve the pain, tenderness, swelling, and stiffness caused by juvenile rheumatoid arthritis (a type of arthritis that affects children) in children 2 years of age and older.",
  },
  {
    id: 10,
    title: "Paroxetine 20mg Tablets",
    image: Paroxetine,
    description:
      "Paroxetine is a selective serotonin reuptake inhibitor (SSRI). It is used to treat several diseases, including major depressive disorder, obsessive-compulsive disorder, social anxiety disorder, panic disorder, posttraumatic stress disorder, generalized anxiety disorder, and premenstrual dysphoric disorder. It is used in the treatment of hot flashes and night sweats that are associated with menopause. This activity outlines the indications, mechanism of action, methods of administration, important adverse effects, contraindications, monitoring, and toxicity of paroxetine, so providers can direct patient therapy in treating conditions for which it is indicated as part of the interprofessional team.",
  },
  {
    id: 11,
    title: "Quinapril 20mg Tablets",
    image: Quinapril,
    description:
      "Quinapril is a medication utilized to manage hypertension, a significant risk factor for coronary heart disease. This activity reviews the indications, mechanism of action, administration, adverse events, contraindications, monitoring, and toxicity of quinapril. In addition, this article aims to highlight pertinent points regarding quinapril as needed by interprofessional teams managing patients with hypertension and other cardiovascular co-morbidities. ",
  },
  {
    id: 12,
    title: "Terazosin 2mg Capsules",
    image: Terazosin,
    description:
      "Terazosin is a medication used in the management and treatment of benign prostatic hyperplasia and essential hypertension. Terazosin is a long-acting, selective alpha-1 adrenergic receptor antagonist. This activity reviews the indications, action, and contraindications for Terazosin as a valuable agent in managing benign prostatic hyperplasia, essential hypertension, and nephrolithiasis.",
  },
  {
    id: 13,
    title: "Terazosin 5mg Capsules",
    image: Terazosin5mg,
    description:
      "Terazosin is a medication used in the management and treatment of benign prostatic hyperplasia and essential hypertension. Terazosin is a long-acting, selective alpha-1 adrenergic receptor antagonist. This activity reviews the indications, action, and contraindications for Terazosin as a valuable agent in managing benign prostatic hyperplasia, essential hypertension, and nephrolithiasis.",
  },
  {
    id: 14,
    title: "Triamterene & Hidrochlorotiazide 37.5mg/25mg Tablets",
    image: TriamtereAndHidrochlorotiazide,
    description:
      "Triamterene is a medication used in the management and treatment of edematous states. It is in the potassium-sparing diuretics class of drugs. This activity outlines the indications, action, and contraindications for triamterene as a valuable agent in managing fluid retaining states and hypertension. This activity will highlight the mechanism of action, adverse event profile, and other key factors (e.g., off-label uses, dosing, pharmacodynamics, pharmacokinetics, monitoring, relevant interactions) pertinent for members of the healthcare team in the management of patients with edematous states and associated conditions.",
  },
  {
    id: 15,
    title: "Triamterene & Hidrochlorotiazide 75mg/50mg Tablets",
    image: TriamtereAndHidrochlorotiazide75,
    description:
      "Triamterene is a medication used in the management and treatment of edematous states. It is in the potassium-sparing diuretics class of drugs. This activity outlines the indications, action, and contraindications for triamterene as a valuable agent in managing fluid retaining states and hypertension. This activity will highlight the mechanism of action, adverse event profile, and other key factors (e.g., off-label uses, dosing, pharmacodynamics, pharmacokinetics, monitoring, relevant interactions) pertinent for members of the healthcare team in the management of patients with edematous states and associated conditions.",
  },
  {
    id: 16,
    title: "Niacin 500mg Capsules",
    image: Niacin500mg,
    description:
      "The uses of niacin are yet to be fully comprehended and embraced. This activity assists in improving knowledge of the benefits niacin has to offer, not only for treatment but also in decreasing the incidence of certain diseases. This activity reviews the pharmacokinetic, administration, indications, contraindications, adverse effects, monitoring, toxicity, and, most importantly, how to enhance interprofessional team outcomes by effectively monitoring the use of this medication",
  },
  {
    id: 17,
    title: "Warfarin Sodium 5mg Tablets",
    image: Warfarin,
    description:
      "Warfarin is a medication used in the prophylaxis and treatment of venous thrombosis and thromboembolic events. It is in the anticoagulant class of drugs. This activity reviews the indications, action, and contraindications for warfarin as a valuable agent in the prophylaxis and treatment of myocardial infarction, deep vein thrombosis, pulmonary embolism, and atrial fibrillation. This activity will highlight the mechanism of action, adverse event profile, pharmacokinetics, and drug interactions pertinent for interprofessional team members to treat patients with venous thrombosis and thromboembolic events.",
  },
  {
    id: 18,
    title: "Xenical 120mg Capsules",
    image: Xenical,
    description:
      "Orlistat is a medication used in the management of obesity. Orlistat acts by reversibly inhibiting gastric and pancreatic lipases. The inactivation of lipases prevents the hydrolysis of triglycerides, and thus free fatty acids are not absorbed. The maximum benefit of orlistat occurs when used in conjunction with diet and exercise. This activity reviews the indications, actions, and contraindications of orlistat as a valuable agent in treating obesity. In addition, this activity will highlight the mechanism of action, adverse event profile, and other key factors (e.g., off-label uses, dosing, pharmacodynamics, pharmacokinetics, monitoring, and relevant interactions) pertinent to members of the interprofessional team in the care of patients with obesity.",
  },
  {
    id: 19,
    title: "Mynocycline Hydrochloride 100mg Tablets",
    image: Mynocycline,
    description:
      "Minocycline belongs to the tetracycline class of antibiotics and is used to manage and treat both infectious and non-infectious conditions. In addition to its effectiveness against both gram-positive and gram-negative bacteria, minocycline also exhibits anti-inflammatory, antioxidant, anti-apoptotic, and immunomodulatory properties. Furthermore, this medication is recognized as the most effective tetracycline derivative in terms of its ability to provide neuroprotective effects. This activity describes minocycline's mechanism of action, indications, and contraindications as a valuable agent in treating and managing bacterial infections and conditions such as acne, neurodegenerative diseases, periodontitis, and dermatitis. This activity also highlights the adverse event profile, dosing, pharmacodynamics, pharmacokinetics, monitoring, and other relevant interactions of minocycline. These are crucial considerations for healthcare professionals engaged in treating patients with the conditions mentioned above.",
  },
  {
    id: 20,
    title: "Mirtazapine 15mg Tablets",
    image: Mirtazapine,
    description:
      "Mirtazapine is an atypical antidepressant and is used primarily for the treatment of a major depressive disorder. Mirtazapine is in a group of tetracyclic antidepressants (TeCA). Mirtazapine inhibits the central presynaptic alpha-2-adrenergic receptors, which causes an increased release of serotonin and norepinephrine. The drug has sedative, antiemetic, anxiolytic, and appetite stimulant effects, which explains its off-label use for insomnia, panic disorder, post-traumatic stress disorder, obsessive-compulsive disorder, generalized anxiety disorder, social anxiety disorder, headaches, and migraines. This activity will highlight the mechanism of action, adverse event profile, pharmacology, monitoring, and relevant interactions of mirtazapine, pertinent for interprofessional team members in treating patients with depression where it is of clinical value.",
  },
  {
    id: 21,
    title: "Quinapril 40mg Tablets",
    image: Quinapril40mg,
    description:
      "Quinapril is a medication utilized to manage hypertension, a significant risk factor for coronary heart disease. This activity reviews the indications, mechanism of action, administration, adverse events, contraindications, monitoring, and toxicity of quinapril. In addition, this article aims to highlight pertinent points regarding quinapril as needed by interprofessional teams managing patients with hypertension and other cardiovascular co-morbidities.",
  },
  {
    id: 22,
    title: "Trazodone Hydrochloride 50mg Tablets",
    image: TrazodoneHydrochloride50mg,
    description:
      "Trazodone is a medication used in the management and treatment of major depressive disorder. It is in the serotonin-antagonist-and-reuptake-inhibitor class of medications. This activity reviews the indications, action, and contraindications for trazodone as a valuable agent in managing major depression. This activity will highlight the mechanism of actions, adverse effects, and other key factors (e.g., off-label uses, dosing, pharmacodynamics, pharmacokinetics, monitoring, relevant interactions), pertinent for members of the interprofessional team in the treatment of patients with depression and related conditions.",
  },
  {
    id: 23,
    title: "Verapamil Hydrochloride 240mg Tablets Extended Release",
    image: Verapamil240mg,
    description:
      "The Food and Drug Administration (FDA) approved indications for verapamil include angina (chronic stable, vasospastic or Prinzmetal variant), unstable angina (crescendo, preinfarction), hypertension as add-on therapy, paroxysmal supraventricular tachycardia (PSVT) prophylaxis, and supraventricular tachycardia (SVT). Verapamil also has numerous non-FDA-approved indications. This activity outlines the indications, mechanism of action, administration methods, significant adverse effects, contraindications, toxicity, and monitoring, of verapamil so providers can direct patient therapy where it is indicated as part of the interprofessional team.",
  },
  {
    id: 24,
    title: "Amitriptiline 50mg Tablets",
    image: Amitriptyline50mg,
    description:
      "Amitriptyline is in the tricyclic antidepressant (TCA) drug classification and acts by blocking the reuptake of both serotonin and norepinephrine neurotransmitters. Amitriptyline is an FDA-approved medication to treat depression in adults. The non-FDA approved indications are anxiety, post-traumatic stress disorder, insomnia, chronic pain (diabetic neuropathy, fibromyalgia), irritable bowel syndrome, interstitial cystitis (bladder pain syndrome), migraine prophylaxis, postherpetic neuralgia, and sialorrhea. This activity reviews the indications, contraindications, activity, adverse events, and other key elements of amitryptiline in the clinical setting related to the essential points needed by members of an interprofessional team managing the care of patients with indications that can benefit from amitriptyline therapy.",
  },
  {
    id: 25,
    title: "Bupropion Hydrochloride 150mg Tablets",
    image: BupropionHydroclodride150mg,
    description:
      "Bupropion is an antidepressant medication that is now also used in smoking cessation. Bupropion has been FDA-approved since 1985. The drug is FDA-approved for adult depression, seasonal affective disorder, and smoking cessation. Off-label, non-FDA approved uses include anti-depressant-induced sexual dysfunction, attention-deficit/hyperactivity disorder (ADHD), depression associated with bipolar disorder, and obesity. In the pediatric population, bupropion is used off-label for ADHD. This activity reviews the mechanism of action, adverse event profile, toxicity, dosing, pharmacodynamics, and monitoring of bupropion, pertinent for members of the interprofessional team for the treatment of patients with indications where bupropion has therapeutic value.",
  },
  {
    id: 26,
    title: "Cyclobenzaprine Hydrochkoride 10mg Tablets",
    image: CyclobenzaprineHydrochloride10mg,
    description:
      "Cyclobenzaprine is FDA-approved as an adjunct to rest for the treatment of muscle spasms associated with acute, painful musculoskeletal conditions. Cyclobenzaprine is a part of a group of medications referred to as cyclical antidepressants. Cyclobenzaprine is a tricyclic amine salt that works in the central nervous system (CNS) as a depressant that reduces muscle hyperactivity. These cyclical antidepressants have roles in treating depression, neuropathic pain, migraine prophylaxis, attention deficit hyperactive disorder, and potential muscle relaxation properties. In addition, this activity reviews the mechanism of action, adverse event profile, toxicity, dosing, pharmacodynamics, and monitoring of cyclobenzaprine for members of the interprofessional team who will potentially prescribe or encounter patients taking cyclobenzaprine.",
  },
  {
    id: 27,
    title: "Donepezyl Hydrochloride 10mg Tablets",
    image: DonepexyHydrochloride10mg,
    description:
      "Donepezil hydrochloride, a piperidine derivative, is a centrally acting, rapid, and reversible acetylcholinesterase inhibitor primarily utilized for treating Alzheimer disease. Acetylcholinesterase is an enzyme that breaks down acetylcholine after its release from the presynapse. By binding reversibly to acetylcholinesterase, donepezil inhibits acetylcholine hydrolysis, thereby increasing acetylcholine availability at the synapses and enhancing cholinergic transmission. The U.S. Food and Drug Administration (FDA) approved the drug for the treatment of dementia in mild, moderate, and severe Alzheimer disease. Although there is currently no evidence to suggest that donepezil can alter the progression of the disease, it has shown efficacy in alleviating specific symptoms by improving cognition and/or behavior in affected individuals. In addition to its FDA-approved use in Alzheimer disease, donepezil has various off-label indications. This activity emphasizes the mechanism of action, adverse event profile, pharmacokinetics, monitoring requirements, and relevant drug interactions of donepezil. This information is crucial for interprofessional team members involved in treating patients with dementia associated with Alzheimer disease.",
  },
  {
    id: 28,
    title: "Famotidine 20mg Tablets",
    image: Famotidine20mg,
    description:
      "Famotidine decreases the production of stomach acid, and its pharmacologic activity is used in the treatment of acid-related gastrointestinal conditions. Famotidine is available both by prescription and over-the-counter (OTC). It is FDA approved and available through prescription for the treatment of duodenal ulcer, gastric ulcer, and gastroesophageal reflux disease (GERD) in adults and children, with a further indication for treatment of pathological hypersecretory conditions in adults. Famotidine is also FDA approved for over-the-counter treatment and prevention of heartburn due to gastroesophageal reflux in adults and pediatrics. It also has off-label uses. This activity outlines the indications, mechanism of action, methods of administration, important adverse effects, contraindications, monitoring, and toxicity of famotidine, so providers can direct patient therapy as needed to achieve positive outcomes.",
  },
  {
    id: 29,
    title: "Ciprofloxacin 500mg Tablets",
    image: Ciprofloxacin500mg,
    description:
      "Ciprofloxacin is an antibiotic agent in the fluoroquinolone class used to treat bacterial infections such as urinary tract infections and pneumonia. Ciprofloxacin has FDA approval to treat urinary tract infections, sexually transmitted infections (gonorrhea and chancroid), skin, bone, joint infections, prostatitis, typhoid fever, gastrointestinal infections, lower respiratory tract infections, anthrax, plague, and salmonellosis. In addition, ciprofloxacin is an appropriate treatment option in patients with mixed infections or patients with predisposing factors for Gram-negative infections. This activity covers ciprofloxacin, a broad-spectrum quinolone antibiotic that members of the interprofessional team need to review its indications, coverage, contraindications, and adverse event profile to optimally manage patients' infectious diseases.",
  },
  {
    id: 30,
    title: "Citalopram 40mg Tablets",
    image: Citalopram40mgTabs,
    description:
      "Citalopram hydrobromide is a selective serotonin reuptake inhibitor. The primary FDA-approved clinical use for citalopram hydrobromide is for treating depression in adults. Off-label uses include alcohol use disorder, coronary arteriosclerosis, obsessive-compulsive disorder, panic disorder, postmenopausal flushing, and premenstrual dysphoric disorder. Citalopram is listed in the WHO model list of essential medicines to treat depressive disorders. Citalopram hydrobromide exerts its antidepressant action by potentiating serotonergic activity in the central nervous system. Multiple studies confirmed that citalopram hydrobromide is a selective serotonin reuptake inhibitor (SSRI) that has minimal effects on norepinephrine and dopamine neuronal reuptake. This activity reviews the indications, mechanism of action, administration, contraindications, monitoring, toxicity associated with citalopram, and the interprofessional team's role in caring for patients with conditions treated with citalopram.",
  },
  {
    id: 31,
    title: "Fluoxetine 10mg Capsules",
    image: Fluoxetina10mgCaps,
    description:
      "Fluoxetine is FDA-approved for major depressive disorder (for patients eight years and older), obsessive-compulsive disorder, panic disorder, bulimia, binge eating disorder, premenstrual dysphoric disorder, and bipolar depression as well as treatment-resistant depression when used in combination with olanzapine. It also has several non-FDA-approved indications. This activity outlines the indications, mechanism of action, dosing, important adverse effects, contraindications, monitoring, and toxicity of fluoxetine, so providers can direct patient therapy to optimal outcomes in combating psychological conditions for which it is indicated.",
  },
  {
    id: 32,
    title: "Atorvastatin Calcium 40mg Tablets",
    image: Atorvastin40mgTabs,
    description:
      "HMG-CoA reductase inhibitors (statins) are lipid-lowering medications used in the primary, secondary, and tertiary prevention of coronary heart disease. Atorvastatin competitively inhibits 3-hydroxy-3-methylglutaryl-coenzyme A (HMG-CoA) reductase. By preventing the conversion of HMG-CoA to mevalonate, statin medications decrease cholesterol production in the liver. Atorvastatin also increases the number of LDL receptors on the surface of hepatic cells. This activity reviews the indications, contraindications, and mechanism of action of atorvastatin to manage coronary heart disease and familial dyslipidemias, covering the indications, contraindications, activity, adverse events, and other key elements of atorvastatin therapy.",
  },
  {
    id: 33,
    title: "HydroOXyzine Hydrochloride 25mg Tablets",
    image: HydroOXYzineHydrochloride25mgTabs,
    description:
      "Hydroxyzine is an antihistamine that reduces the effects of natural chemical histamine in the body. Histamine can produce symptoms of itching, or hives on the skin. Hydroxyzine may be used to treat allergic skin reactions such as hives or contact dermatitis. Since hydroxyzine also reduces activity in the central nervous system, it can be used as a sedative to treat anxiety and tension. It is also used together with other medications given during and after general anesthesia.",
  },
  {
    id: 34,
    title: "Lisinopril 40mg Tablets",
    image: Lisinopril40mgTabs,
    description:
      "Lisinopril is classified as an angiotensin-converting enzyme inhibitor and has been available for nearly three decades. Lisinopril has some key features that make it different from enalapril and captopril; 1) it has a long half-life, 2) it is hydrophilic, and 3) it is not broken down by the liver. Lisinopril is a competitive inhibitor of angiotensin-converting enzyme (ACE) and prevents the conversion of angiotensin I to angiotensin II, which is a potent vasoconstrictor. This activity covers lisinopril, including mechanism of action, pharmacology, adverse event profiles, eligible patient populations, monitoring, and highlights the role of the interprofessional team in the management of hypertension with lisinopril.",
  },

  {
    id: 35,
    title: "Lisinopril And Hydrochlorotiazide 20mg/12.5mg Tablets",
    image: LisinoprilAndhydroclorotiazide20m,
    description:
      "Lisinopril is classified as an angiotensin-converting enzyme inhibitor and has been available for nearly three decades. Lisinopril has some key features that make it different from enalapril and captopril; 1) it has a long half-life, 2) it is hydrophilic, and 3) it is not broken down by the liver. Lisinopril is a competitive inhibitor of angiotensin-converting enzyme (ACE) and prevents the conversion of angiotensin I to angiotensin II, which is a potent vasoconstrictor. This activity covers lisinopril, including mechanism of action, pharmacology, adverse event profiles, eligible patient populations, monitoring, and highlights the role of the interprofessional team in the management of hypertension with lisinopril.",
  },
  {
    id: 36,
    title: "Lithium Carbonate 300mg Capsules",
    image: LithiumCarbonate300mg,
    description:
      "This medication is used to treat manic-depressive disorder (bipolar disorder). It works to stabilize the mood and reduce extremes in behavior by restoring the balance of certain natural substances (neurotransmitters) in the brain.Some of the benefits of continued use of this medication include decreasing how often manic episodes occur and decreasing the symptoms of manic episodes such as exaggerated feelings of well-being, feelings that others wish to harm you, irritability, anxiousness, rapid/loud speech, and aggressive/hostile behaviors.",
  },
  {
    id: 37,
    title: "Mynocicline Hydrocloride 50mg Capsules",
    image: MynociclineHydrocloride50mgCaps,
    description:
      "Minocycline belongs to the tetracycline class of antibiotics and is used to manage and treat both infectious and non-infectious conditions. In addition to its effectiveness against both gram-positive and gram-negative bacteria, minocycline also exhibits anti-inflammatory, antioxidant, anti-apoptotic, and immunomodulatory properties. Furthermore, this medication is recognized as the most effective tetracycline derivative in terms of its ability to provide neuroprotective effects. This activity describes minocycline's mechanism of action, indications, and contraindications as a valuable agent in treating and managing bacterial infections and conditions such as acne, neurodegenerative diseases, periodontitis, and dermatitis. This activity also highlights the adverse event profile, dosing, pharmacodynamics, pharmacokinetics, monitoring, and other relevant interactions of minocycline. These are crucial considerations for healthcare professionals engaged in treating patients with the conditions mentioned above.",
  },
  {
    id: 38,
    title: "Mynocicline Hydrocloride 100mg Capsules",
    image: MynociclineHydrocloride100mgCaps,
    description:
      "Minocycline belongs to the tetracycline class of antibiotics and is used to manage and treat both infectious and non-infectious conditions. In addition to its effectiveness against both gram-positive and gram-negative bacteria, minocycline also exhibits anti-inflammatory, antioxidant, anti-apoptotic, and immunomodulatory properties. Furthermore, this medication is recognized as the most effective tetracycline derivative in terms of its ability to provide neuroprotective effects. This activity describes minocycline's mechanism of action, indications, and contraindications as a valuable agent in treating and managing bacterial infections and conditions such as acne, neurodegenerative diseases, periodontitis, and dermatitis. This activity also highlights the adverse event profile, dosing, pharmacodynamics, pharmacokinetics, monitoring, and other relevant interactions of minocycline. These are crucial considerations for healthcare professionals engaged in treating patients with the conditions mentioned above.",
  },
  {
    id: 39,
    title: "Quinapril 40mg Tablets",
    image: Quinapril40mg,
    description:
      "Quinapril is a medication utilized to manage hypertension, a significant risk factor for coronary heart disease. This activity reviews the indications, mechanism of action, administration, adverse events, contraindications, monitoring, and toxicity of quinapril. In addition, this article aims to highlight pertinent points regarding quinapril as needed by interprofessional teams managing patients with hypertension and other cardiovascular co-morbidities.",
  },
  {
    id: "WD8IvwsiLLS5tiJ",
    title: "BusPIRone Hydrochloride 10mg Tablets",
    image: Buspirone10mgTabs,
    description:
      "This medication is used to treat anxiety. It may help you think more clearly, relax, worry less, and take part in everyday life. It may also help you to feel less jittery and irritable, and may control symptoms such as trouble sleeping, sweating, and pounding heartbeat. Buspirone is a medication for anxiety (anxiolytic) that works by affecting certain natural substances in the brain (neurotransmitters).",
  },
  {
    id: "mVh7nLmeCba4bI2",
    title: "Cialis 20mg Tablets",
    image: Cialis20mgTabs,
    description:
      "Tadalafil is used to treat male sexual function problems (impotence or erectile dysfunction-ED). In combination with sexual stimulation, tadalafil works by increasing blood flow to the penis to help a man get and keep an erection.Tadalafil is also used to treat the symptoms of an enlarged prostate (benign prostatic hyperplasia-BPH). It helps to relieve symptoms of BPH such as difficulty in beginning the flow of urine, weak stream, and the need to urinate often or urgently (including during the middle of the night). Tadalafil is thought to work by relaxing the smooth muscle in the prostate and bladder.This drug does not protect against sexually transmitted diseases (such as HIV, hepatitis B, gonorrhea, syphilis). Practice safe sex such as using latex condoms. Consult your doctor or pharmacist for more details.",
  },
  {
    id: "kC5qbgTVmPklne2",
    title: "Ramipril 10mg Capsules",
    image: Ramipril10mgCaps,
    description: "Ramipril is used to treat high blood pressure (hypertension). Lowering high blood pressure helps prevent strokes, heart attacks, and kidney problems. Ramipril is also used to improve survival after a heart attack. It may also be used in high risk patients (such as patients with heart disease/diabetes) to help prevent heart attacks and strokes. This medication may also be used to treat heart failure in patients who have had a recent heart attack.Ramipril is an ACE inhibitor and works by relaxing blood vessels so that blood can flow more easily.",
  },
  {
    id: "pTf6xHjnAa6dDII",
    title: "rOPINIRole 1*mg Tablets",
    image: Ropinirole1mgTabs,
    description: "This medication is used alone or with other medications to treat Parkinson's disease. It can improve your ability to move and decrease shakiness (tremor), stiffness, slowed movement, and unsteadiness. It may also decrease the number of episodes of not being able to move on-off syndrome. Ropinirole is also used to treat restless legs syndrome (RLS). It may improve your sleep by decreasing the urge to move your legs and decreasing uncomfortable/unpleasant feelings in the legs.This medication works by helping to restore the balance of a certain natural substance (dopamine) in the brain",
  },
  {
    id: "QqpwfXwCE9qi8Lz",
    title: "Sulfamethoxazole and Trimethoprim 800mg/160mg Tablets",
    image: SulfamethoxazoleAndTrimethoprim800mg600mgTabs,
    description: "This medication is a combination of two antibiotics: sulfamethoxazole and trimethoprim. It is used to treat a wide variety of bacterial infections (such as middle ear, urine, respiratory, and intestinal infections). It is also used to prevent and treat a certain type of pneumonia (pneumocystis-type).This medication should not be used by children less than 2 months of age due to the risk of serious side effects.This medication treats only certain types of infections. It will not work for viral infections (such as flu). Unnecessary use or misuse of any antibiotic can lead to its decreased effectiveness.",
  },
  {
    id: "w9NJWxgcvIgRHGw",
    title: "Vitamin D (Ergocalciferol) 1.25mg Capsules",
    image: VitaminDCaps,
    description: "Vitamin D (ergocalciferol-D2, cholecalciferol-D3, alfacalcidol) is a fat-soluble vitamin that helps your body absorb calcium and phosphorus. Having the right amount of vitamin D, calcium, and phosphorus is important for building and keeping strong bones. Vitamin D is used to treat and prevent bone disorders (such as rickets, osteomalacia). Vitamin D is made by the body when skin is exposed to sunlight. Sunscreen, protective clothing, limited exposure to sunlight, dark skin, and age may prevent getting enough vitamin D from the sun.Vitamin D with calcium is used to treat or prevent bone loss (osteoporosis). Vitamin D is also used with other medications to treat low levels of calcium or phosphate caused by certain disorders (such as hypoparathyroidism, pseudohypoparathyroidism, familial hypophosphatemia). It may be used in kidney disease to keep calcium levels normal and allow normal bone growth. Vitamin D drops (or other supplements) are given to breast-fed infants because breast milk usually has low levels of vitamin D."
  },
  {
    id: "JGXrRv7WqsFDbZD",
    title: "Viagra 100mg Tablets",
    image: Viagra100mgTabs,
    description: "Sildenafil is used to treat male sexual function problems (impotence or erectile dysfunction-ED). In combination with sexual stimulation, sildenafil works by increasing blood flow to the penis to help a man get and keep an erection.This drug does not protect against sexually transmitted diseases (such as HIV, hepatitis B, gonorrhea, syphilis). Practice safe sex such as using latex condoms. Consult your doctor or pharmacist for more details.",
  },

];

export default products;
