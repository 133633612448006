import React from "react";
import products from "../product-list";
import '../pages/show-card-info.css'

import { useParams } from "react-router-dom";
/* eslint-disable */

function ShowCardInfo() {
  let { ProductId } = useParams();
  let selectProduct = products.find((product) => product.id == ProductId);
  // eslint-disable-next-line
  console.log(selectProduct);
  return (
    <div className="selected-product-container">
      <img  className="product-image-description"    src={selectProduct.image}/>
      <div className="product-description-container">
      <h1 id="title-description">{selectProduct.title}</h1>

      <p>{selectProduct.description}</p>
    </div>
    </div>
  );
}

export default ShowCardInfo;
