import MyAccountNav from "../../views/MyAccountNav";
import "../pages/Mexican-products.css";
import Acxion064mgTabs from "../assets/Mexican-Products/Acxion-064mg-tabs.webp";
import Acxion30mgTabs from "../assets/Mexican-Products/Acxion-30mg-tabs.webp";
import AcxionAP30mgTabs from "../assets/Mexican-Products/AcxionAP-30mg-tabs.webp";
import Alpralozam2mgTabs from "../assets/Mexican-Products/Alprazolam-2mg-tabs.webp";
import Alpralozam025mgTabs from "../assets/Mexican-Products/Alprazolam-0,25mg-tabs.webp";
import Alpralozam050mgTabs from "../assets/Mexican-Products/Alprazolam-0,50mg-tabs.webp";
import Anfebutamona150mgTabs from "../assets/Mexican-Products/Anfebutamona-150mg-tabs.webp";
import Bromazepan3mgTabs from "../assets/Mexican-Products/Bromazepan-3mg-tabs.webp";
import Butronin10mgTabs from "../assets/Mexican-Products/Butronin-10mg-tabs.webp";
import Clonazepam2mgTabs from "../assets/Mexican-Products/Clonazepam-2mg-tabs.webp";
import Clonazepam025mgGot from "../assets/Mexican-Products/Clonazepam-2.5mg-10ml-gotero.webp";
import DietyAP20mgTabs from "../assets/Mexican-Products/DietyAP-20mg-tabs.webp";
import Duloxetina60mgCaps from "../assets/Mexican-Products/Duloxetina-60mg-caps.webp";
import Ifafonal10mgTabs from "../assets/Mexican-Products/Ifafonal-10mg-tabs.webp";
import Metilfenidato10mgTabs from "../assets/Mexican-Products/Ifafonal-10mg-tabs.webp";
import Itravil60mgTabs from "../assets/Mexican-Products/ItrovilAP-60mg-tabs.webp";
import ItravilAP60mgTabs from "../assets/Mexican-Products/Itravil-60mg-tabs.webp";
import Lose1mgTabs from "../assets/Mexican-Products/Lose-1mg-tabs.webp";
import Lose2mgTabs from "../assets/Mexican-Products/Lose-2mg-tabs.webp";
import Metilfenidato10mgx60Tabs from "../assets/Mexican-Products/Metilfenidato-10mg-tabs.webp";
import Norex50mgTabs from "../assets/Mexican-Products/Norex-50mg-tabs.webp";
import NorexAP75mgTabs from "../assets/Mexican-Products/NorexAP-75mg-tabs.webp";

const MexicanProduct = [
  {
    id: "h8Ui9yCuhargdMu",
    title: "Acxion 6.4mg Tabletas",
    image: Acxion064mgTabs,
  },
  {
    id: "SV3hupAADrulah2",
    title: "Acxion 30mg Tablets",
    image: Acxion30mgTabs,
  },
  {
    id: "SIEUHCwADrilhho2",
    title: "AcxionAP 30mg Tablets",
    image: AcxionAP30mgTabs,
  },
  {
    id: "ctDbthpWPgzVixc",
    title: "Alpralozam 2mg Tabletas",
    image: Alpralozam2mgTabs,
  },
  {
    id: "ctDbtUjJhGdSrTGl",
    title: "Alpralozam 0.50mg Tabletas",
    image: Alpralozam050mgTabs,
  },
  {
    id: "ctDbthpWagztiuj",
    title: "Alpralozam 0.25mg Tabletas",
    image: Alpralozam025mgTabs,
  },
  {
    id: "nKfvyXkgXLl2025",
    title: "Anfebutamona 150mg Tabletas",
    image: Anfebutamona150mgTabs,
  },
  {
    id: "nrZIIE0OO8Rb9mj",
    title: "Bromazepan 30mg Tabletas",
    image: Bromazepan3mgTabs,
  },
  {
    id: "QEIwEF3jTxCoIF3",
    title: "Butronin 10mg Tabletas",
    image: Butronin10mgTabs,
  },
  {
    id: "BrJgsqVx9DD11Cr",
    title: "Clonazepam 2mg Tabletas",
    image: Clonazepam2mgTabs,
  },
  {
    id: "BrJgsVx9D31gqyCr",
    title: "Clonazepam 2.5mg/10ml Gotero",
    image: Clonazepam025mgGot,
  },
  {
    id: "Z5hrMrlQ4RBYPmG",
    title: "DietyAP 30mg Tabletas",
    image: DietyAP20mgTabs,
  },
  {
    id: "FLXOUQr5ZTcmHcj",
    title: "Duloxetina 60mg Capsulas",
    image: Duloxetina60mgCaps,
  },

  {
    id: "JAprtLt234KM2F7",
    title: "Ifafonal 10mg Tabletas",
    image: Ifafonal10mgTabs,
  },
  {
    id: "o7SCjMLzFSk7ody",
    title: "Metilfenidato 10mg Tabletas",
    image: Metilfenidato10mgTabs,
  },

  {
    id: "rut6w1rtrQZEEoe",
    title: "ItravilAP 60mg Tabletas",
    image: ItravilAP60mgTabs,
  },
  {
    id: "iVyELc5Y6jTN5Bm",
    title: "Itravil 30mg Tabletas",
    image: Itravil60mgTabs,
  },

  {
    id: "lVzwTYxgM9cuUUK",
    title: "Lose 1mg Tabletas",
    image: Lose1mgTabs,
  },
  {
    id: "lVzwTYxgM957eUK",
    title: "Lose 1mg Tabletas",
    image: Lose2mgTabs,
  },
  {
    id: "Z23Eq7Hfz6DvsmK",
    title: "Metilfenidato 10mg Tabletas",
    image: Metilfenidato10mgx60Tabs,
  },
  {
    id: "SSR9rXt2jxZgpk5",
    title: "NorexAp 75mg Tabletas",
    image: NorexAP75mgTabs,
  },
  {
    id: "0ywNt2qlp9Q80um",
    title: "Norex 50mg Tabletas",
    image: Norex50mgTabs,
  },
];

function MexicanProducts() {
  return (
    <div>
      <MyAccountNav />
      <div className="container">
        <div className="product-container">
          <div className="row">
            {MexicanProduct.map((MexProduct) => (
              <div className="col-md-4 gy-4" key={MexProduct.id}>
                <div>
                  <img src={MexProduct.image} alt="image" className="product-image"/>
                  <p className="product-title">{MexProduct.title}</p>
                </div>
              </div>
            ))}
          </div>
        </div>
      </div>
    </div>
  );
}

export default MexicanProducts;
