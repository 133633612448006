import React from "react";
import email from "../assets/icons/icons8-nuevo-post-50.png";
import distribution from "../assets/icons/icons8-entrega-50.png";
import support from "../assets/icons/icons8-atención-al-cliente-50.png";
import location from "../assets/icons/icons8-marcador-50.png"
import "./Contact-us.css";

function Contact() {
  return (
    <div className="container">
      <div className="contact-us_container">
        <form
          id="contact-us_form"
          action="https://formsubmit.co/dutyfreesupplyhouse@gmail.com"
          method="POST"
        >
          <h1 className="display-5 fw-bold">CONTACT US</h1>
          <label htmlFor="contact-us_name">Your Name:</label>
          <input type="name" name="contact-us_request_from" />
          <label htmlFor="contact-us_country">Country:</label>
          <input type="text" id="contact-us_country" name="Country" />
          <label htmlFor="contact-us_where_did_you_hear_about_us">
            Who recommended you with us, or where did you hear about us?
          </label>
          <input
            type="text"
            id="contact-us_where_did_you_hear_about_us"
            name="Who recommended you with us, or where did you hear about us?"
          />
          <label htmlFor="contact-us_email">Email:</label>
          <input type="email" id="contact-us_email" name="email" />
          <label htmlFor="contact-us_product_you_request">
            Subject (Product you request):
          </label>
          <input type="text" id="contact-us_product_you_request" />
          <label htmlFor="contact-us_product_you_request">Your Message: </label>
          <input type="text" id="contact-us_product_you_request" />
          <p className="spam">
            Please check your spam blocker, trash filter or junk mail folder now
            in case our first e-mail went to this folder. Please help us to mar
            this first e-mail as a “safe mail” so you can receive our further
            e-mails in your inbox folder.
          </p>
          <input className="bg-primary text-white " type="submit" />
        </form>

        <div className="contact-us_office_Info_container">
          <div className="contact-us_office_text">
            <h1 className="display-5 fw-bold">THE OFFICE</h1>
            <div>
              <img src={email} alt="email-icon" />
              <p className="lead fw-bold">
                Email: info@dutyfreesupplyhouse.com
              </p>
            </div>
            <div>
              <img src={distribution} alt="distribution-icon" />
              <p className="lead fw-bold">
                Distribution and Sale in Different Countries.
              </p>
            </div>
            <div>
              <img src={support} alt="support-icon" />
              <p className="lead fw-bold">Support Forum for Over 24Hrs.</p>
            </div>
            <div>
              <img src={location} alt="location-icon"/>
              <p className="lead fw-bold">
                Address: San Diego, CA CEDIS: Los Angeles, CA
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Contact;
