import React from "react";
import Banner from "../Banner"
import Banner3 from "../Banner3"
import { useAuth0 } from "@auth0/auth0-react";
import MyAccount from "../../views/MyAccount";



function Home() {
  const {isAuthenticated} = useAuth0();
return (

  isAuthenticated ? <MyAccount/> : 
    <div>
    <Banner/>
    <Banner3/>
    </div>
  );
}

export default Home;
