import React from "react";
import BoldQv200 from "./assets/Anabolics/Bold-Qv-200.jpg";
import DecaQv300 from "./assets/Anabolics/Deca-QV-300.jpg";
import EnantatQV250 from "./assets/Anabolics/Enantat-QV-250.jpg";
import Gentropin10mg from "./assets/Anabolics/Gentropin-10mg.png";
import Test400 from "./assets/Anabolics/Test-400.jpg";
import TestQv200 from "./assets/Anabolics/Test-QV-200.jpg";
import Testosterona4LA from "./assets/Anabolics/Testosterona-4L-A-10ml.webp";
import TestosteroneCypionate200mg from "./assets/Anabolics/Testosterone-cypionate-injection-200mg.webp"
import TrembolonaQv75 from "./assets/Anabolics/Trembolona-QV-75.jpg"
import MyAccountNav from "../views/MyAccountNav";
import PropionatQv100 from "./assets/Anabolics/Propionat-QV-100.jpg"
import Winstrolv50mg from "./assets/Anabolics/Winstrol-v-50mg-ml.jpeg"
import Equipoise50ml from "./assets/Anabolics/Equipoise-50ml.jpeg"
import "./Anabolics.css"



const anabolicsProducts = [
  {
    id: "D5ZSSA91UCu5ZpA",
    title: "Bold-Qv-200 10ml ",
    image: BoldQv200,
    description: "Steroids",
  },
  {
    id: "K01lweD2QQwFKyv",
    title: "Deca QV-300 10ml ",
    image: DecaQv300,
    description: "",
  },
  {
    id: "iv3mfRiLRE2CUm0",
    title: "Enantat Qv-250 10ml",
    image: EnantatQV250,
    description: "",
  },
  {
    id: "DDCubJRIizIE7Pt",
    title: "Gentropin 10mg/2ml ",
    image: Gentropin10mg,
    description: "",
  },
  {
    id: "icco4Pgq5kImjTU",
    title: "Propionat Qv-100",
    image: PropionatQv100,
    description: "",
  },
  {
    id: "WL52aBxXeU3Ggef",
    title: "Test-400",
    image: Test400,
    description: "",
  },
  {
    id: "3zvhdPWRhy73vPV",
    title: "Test Qv-200",
    image: TestQv200,
    description: "",
  },
  {
    id: "aIf4AvbluvjzjEA",
    title: "Testosterona 4L-A",
    image: Testosterona4LA,
    description: "",
  },
  {
    id: "6pg6jPySBsDBI98",
    title: "Testosterone Cypionate 100mg",
    image: TestosteroneCypionate200mg,
    description: "",

  },
  {
    id: "RC53YSONhIBothj",
    title: "Trembolona Qv-75",
    image: TrembolonaQv75,
  },
  {
    id: "q8b3TYXhxyuhQVS",
    title: "Winstrol-V 50mg/ml",
    image: Winstrolv50mg
  },
  {
    id: "tYFCcp107FtpueI",
    title: "Equipoise 50ml",
    image: Equipoise50ml,
  }
];

function Anabolics() {
  return (<div>
    <MyAccountNav/>
    <div className="container">
      <div className="product-container">
        <div className="row">
          {anabolicsProducts.map((Anabolic) => (
            <div className="col-md-4 gy-4" key={Anabolic.id}>
              <div>
                <img src={Anabolic.image} alt="" id="anabolics-product-image" />
                <p className="product-title fw-bold">{Anabolic.title}</p>
              </div>
            </div>
          ))}
        </div>
      </div>
    </div>
    </div>
  );
}

export default Anabolics;
