import React from "react";
import "./banner.css";

const Banner = () => {
  return (
    <div className="Banner">
      <h1 className="display-1 fw-bold">
        The place were whe care about your <span id="health">health</span>
      </h1>
      <p className="banner-paragraph">
        "Duty Free Supply House offers quality prescription medications from
        recognized laboratories.<span> Since 1997 serving our loyal customers</span>"
      </p>
    </div>
  );
};

export default Banner;
