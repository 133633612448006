import { Route, Routes } from "react-router-dom";
import FAQs from "./components/pages/FAQs";
import Contact from "./components/pages/Contact-us";
import About from "./components/pages/about-us";
import FooterList from "./components/FooterList";
import { useAuth0 } from "@auth0/auth0-react";
import Loading from "./components/Loading";
import { AuthenticationGuard } from "./auth/protected-route";
import MyAccount from "./views/MyAccount";
import Products from "./components/pages/Products";
import Footer from "./components/Footer";
import Home from "./components/pages/Home";
import NavBar from "./components/navbar";
import ShowCardInfo from "./components/pages/show-cardinfo";
import Anabolics from "./components/Anabolics";
import AmericanProducts from "./components/pages/AmericanProducts";
import MexicanProducts from "./components/pages/Mexican-products";
import Careers from "./components/pages/carrers";
import Vacantes from "./components/pages/vacantes";
function App() {
  const { isLoading } = useAuth0();
  if (isLoading) {
    return <Loading />;
  }

  return (
    <div className="App dp-flex-column h-100 w-100">
      <NavBar />

      <div className="flex-grow-1">
        <Routes>
          <Route path="/" element={<Home />} />
          <Route path="/FAQs" element={<FAQs />} />
          <Route path="/Products" element={<Products />} />
          <Route path="/About" element={<About />} />
          <Route path="/Contact" element={<Contact />} />
          <Route path="/Careers" element={<Careers />} />
          <Route path="/vacantes" element={<Vacantes />} />
          <Route path="/Products/:ProductId" element={<ShowCardInfo />} />
          <Route path="/AmericanProducts" element={<AmericanProducts />} />
          <Route
            path="/Anabolics"
            element={<AuthenticationGuard component={Anabolics} />}
          />
          <Route
            path="/MexicanProducts"
            element={<AuthenticationGuard component={MexicanProducts} />}
          />
          <Route
            path="/MyAccount"
            element={<AuthenticationGuard component={MyAccount} />}
          />
        </Routes>
      </div>

      <FooterList />
      <Footer />
    </div>
  );
}

export default App;
