import React from "react";
import userIcon from "../components/assets/icons/icons8-usuario-30.png";
import AuthButton from "../components/auth-button"
import { Link } from "react-router-dom";
import "./MyAccount.css"

function MyAccountNav() {
  return (
    <div>
      <div className="dashboard">
        <img src={userIcon} alt="User" id="user-icon" />
        
        <Link to="/Anabolics">ANABOLICS</Link>
        <Link to="/AmericanProducts">AMERICAN PRODUCTS</Link>
        <Link to="/MexicanProducts">MEXICAN PRODUCTS</Link>
        <AuthButton/>
      </div>
    </div>
  );
}

export default MyAccountNav;
