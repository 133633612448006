import "./FAQs.css";
import Accordion from "react-bootstrap/Accordion";





function BasicExample() {
  return (
    <>
    
      <div className="container">
        <h1>FAQs</h1>

        <Accordion defaultActiveKey="0">
          <Accordion.Item eventKey="0">
            <Accordion.Header className="Accordion-header">
              What are the benefits of ordering from you?
            </Accordion.Header>
            <Accordion.Body className="lead">
              All our medications are from FDA approved manufacturers in America
              and Europe and also all orders are guaranteed to arrive to your
              door step. All shipments are sent via two days air and/or second
              day air by qualified courier companies, so you can track your
              shipment online.
            </Accordion.Body>
          </Accordion.Item>
          <Accordion.Item eventKey="1">
            <Accordion.Header className="Accordion-header">
              How do i track my order?{" "}
            </Accordion.Header>
            <Accordion.Body className="lead">
              If you are a current customer and would like to track your order,
              you can contact us by sending us an e-mail and request an updated
              (In case we don’t send first the tracking number).
            </Accordion.Body>
          </Accordion.Item>
          <Accordion.Item eventKey="2">
            <Accordion.Header className="Accordion-header">
              How do i receive information about ordering?
            </Accordion.Header>
            <Accordion.Body className="lead">
              If you are a current customer and would like to track your order,
              you can contact us by sending us an e-mail and request an updated
              (In case we don’t send first the tracking number).
            </Accordion.Body>
          </Accordion.Item>
          <Accordion.Item eventKey="3">
            <Accordion.Header className="Accordion-header">
              What is the charge for the shipping?
            </Accordion.Header>
            <Accordion.Body className="lead">
              This depends on your country and most of the times it is free
              shipping depending of the product you request and the quantity of
              product you need, and we use special courier companies via second
              day air and/or two days air, except Saturday and Sunday.
            </Accordion.Body>
          </Accordion.Item>
          <Accordion.Item eventKey="4">
            <Accordion.Header className="Accordion-header">
              What quantity of product i can buy at once?
            </Accordion.Header>
            <Accordion.Body className="lead">
              All of our orders fall under the 90 day supply rule, and this
              depend of the patient condition.
            </Accordion.Body>
          </Accordion.Item>
          <Accordion.Item eventKey="5">
            <Accordion.Header className="Accordion-header">
              What are the FDA-Regulations?
            </Accordion.Header>
            <Accordion.Body className="lead">
              Every country has different F.D.A.- Regulations, and it is the
              obligation of each customer to check the ones from their country
              but all our medications fall under the 90 day supply rule.
            </Accordion.Body>
          </Accordion.Item>
          <Accordion.Item eventKey="6">
            <Accordion.Header className="Accordion-header">
              Do you accept returns?
            </Accordion.Header>
            <Accordion.Body className="lead">
              If you receive the wrong medication, and also if the medication
              does not work as it is supposed to, please contact us immediately,
              and we will work on your problem to solve it to your satisfaction.
            </Accordion.Body>
          </Accordion.Item>
          <Accordion.Item eventKey="7">
            <Accordion.Header className="Accordion-header">
              Is there a minimun order ammount?
            </Accordion.Header>
            <Accordion.Body className="lead">
              The minimum order amount we sale its by one Month supply (30 days
              supply) But if You want to try our products and service for the
              first time, You can pay just two week supply (15 days supply) And
              this way You can know our system.
            </Accordion.Body>
          </Accordion.Item>
          <Accordion.Item eventKey="8">
            <Accordion.Header className="Accordion-header">
              Is there any hidden charges after i make the payment?
            </Accordion.Header>
            <Accordion.Body className="lead">
              We let you know on each of your orders the exact amount you have
              to pay and we also previously send the estimate with the product
              specifications, quantity of product, shipping fees and the total
              amount (Never hidden charges).
            </Accordion.Body>
          </Accordion.Item>
          <Accordion.Item eventKey="9">
            <Accordion.Header className="Accordion-header">
              What is your suggestion for order?
            </Accordion.Header>
            <Accordion.Body className="lead">
              The most You order, the most we can reduce our costs, and you can
              get free shipping, handling and processing (the postal charge is
              separate) that way you save more money and also you receive free
              medication. Ps. Please check the ordering procedures from the
              pharmacy, because the more you order, the most you save, please
              check the price of each tablet in the price list.
            </Accordion.Body>
          </Accordion.Item>
          <Accordion.Item eventKey="10">
            <Accordion.Header className="Accordion-header">
              Do you offer refills?
            </Accordion.Header>
            <Accordion.Body className="lead">
              Yes, all orders after your initial order can be refilled, we also
              have internal records of each customer, and we can see each file
              (folder) for an easy way to refill. The file and the information
              you provide, is exclusive information of freezonepharma.com and no
              one have access to your information, because we don’t share this
              in any way to a third persons.
            </Accordion.Body>
          </Accordion.Item>
          <Accordion.Item eventKey="11">
            <Accordion.Header className="Accordion-header">
              Do you accept credit cards?
            </Accordion.Header>
            <Accordion.Body className="lead">
              Yes, We do take credit cards for on-line payments via WESTERN
              UNION and/or MONEY GRAM, and this is a really fast and easy way to
              send wire payment with no inconvenience.
            </Accordion.Body>
          </Accordion.Item>
          <Accordion.Item eventKey="12">
            <Accordion.Header className="Accordion-header">
              What other kind of payment do you accept?
            </Accordion.Header>
            <Accordion.Body className="lead">
              We just open a new address to receive payments, as money orders,
              traveler checks and also different currency payments via regular
              mail, and for this way of payments, please ask for instructions,
              because you have to follow the instructions so we can avoid mail
              lifters and any other issues.
            </Accordion.Body>
          </Accordion.Item>
          <Accordion.Item eventKey="12">
            <Accordion.Header className="Accordion-header">
              How do i know if my payment it's going to be complete at the time
              that you receive it via FedEx overnight?
            </Accordion.Header>
            <Accordion.Body className="lead">
              Ps. Please make sure you send the right amount of money, because
              we record with a video camera every single payment we receive via
              regular mail since the envelope is seal, until we check the 100%
              of the envelope inside, so please make sue you do everything as
              the instructions mark so your order don’t have to delay for one
              simple mistake.
            </Accordion.Body>
          </Accordion.Item>
          <Accordion.Item eventKey="13">
            <Accordion.Header className="Accordion-header">
              What discounts do you offer for return customers or large orders
            </Accordion.Header>
            <Accordion.Body className="lead">
              We want your business and we want to make sure you come back, we
              make special offers all the time, and we have good discounts
              depending of what kind of customer you are, (V.I.P., regular
              member, old member, frequent member, large orders member etc…) we
              take personal care of each customer. simple mistake.
            </Accordion.Body>
          </Accordion.Item>
          <Accordion.Item eventKey="14">
            <Accordion.Header className="Accordion-header">
              Is there any possibility of a delay of my order?
            </Accordion.Header>
            <Accordion.Body className="lead">
              The only possible delay is that you ordered a rare medication, or
              if we don’t have it in stock or your order is not complete here,
              we have to make a special request with the supplier and sometimes
              can delay it by around 3 to 4 working days but we usually have
              stock of all our products from the price list.
            </Accordion.Body>
          </Accordion.Item>
          <Accordion.Item eventKey="15">
            <Accordion.Header className="Accordion-header">
              How do i know when you receive my WESTERN UNION and/or MONEY GRAM
              wire payment?
            </Accordion.Header>
            <Accordion.Body className="lead">
              First you have to refill the full information we request from you
              so we can process your order and print your label while we cash
              the payment, and after we cash the payment, we send you a
              notification letting you know that we are ready to proceed.
            </Accordion.Body>
          </Accordion.Item>
          <Accordion.Item eventKey="16">
            <Accordion.Header className="Accordion-header">
              How do i know when you receive my envelope payment?
            </Accordion.Header>
            <Accordion.Body className="lead">
              When you send your order (Payment) via FedEx over night, we
              recommend you to send this from Monday to Thursday and this way
              the envelope will not be the whole weekend at FedEx facilities and
              we don’t have the risk to be stolen Sometimes we don’t know when
              you are sending the order, because weekends and holidays come
              through, that’s why we count only the working days, because the
              weekends, and holidays, they don’t open the postal annex and FedEx
              facilities, but as soon as we receive the payment, we proceed
              immediately. (We almost never delay, because over night almost the
              100% of the envelopes arrive safe a day after, but just in case,
              please try to make your order with enough advance time so you
              don’t run out of medication).
            </Accordion.Body>
          </Accordion.Item>
          <Accordion.Item eventKey="17">
            <Accordion.Header className="Accordion-header">
              Do you ship to any country?
            </Accordion.Header>
            <Accordion.Body className="lead">
              Yes, however it is your responsibility to check your local
              regulations on mail-order medications please, but don’t worry much
              because we never have problems with the customs because we refill
              the full information and the commercial invoice so all orders can
              arrive with no problems at all.
            </Accordion.Body>
          </Accordion.Item>
          <Accordion.Item eventKey="18">
            <Accordion.Header className="Accordion-header">
              Are products shipped discretely for my protection?
            </Accordion.Header>
            <Accordion.Body className="lead">
              To respect the right of privacy of our customers, we do not put
              any “Medical” label in any part of the packing slip or envelope,
              and if you want your product to be delivered to your establishment
              or your work, no one will know what the package contains. Why is
              there a handling charge (courier charge) as well as a shipping
              charge? Our staff of delivery drivers spends many hours a day
              driving and dealing with the traffic, because they have to drive
              to send the orders out from a safe place away from customs issues
              and avoid mail lifters, and this way you receive your order on
              time, as the way we send the orders out, it’s the most reliable
              way.
            </Accordion.Body>
          </Accordion.Item>
          <Accordion.Item eventKey="19">
            <Accordion.Header className="Accordion-header">
              Do i receive my order two days after i pay it?
            </Accordion.Header>
            <Accordion.Body className="lead">
              We do send the orders via special couriers via two days air and/or
              second day air, and we pay the exact amount for the shipping fee,
              and after we drop the mail in the courier company, it’s their
              commitment to deliver your order two days after we give the order
              to them, and if there is any delay because of them we can’t make
              ourselves responsible for that. Ps. Usually this kind of delays
              happen only when they have bad weather, but almost never, as we do
              use the FedEx and U.P.S. Courier companies.
            </Accordion.Body>
          </Accordion.Item>
          <Accordion.Item eventKey="20">
            <Accordion.Header className="Accordion-header">
              Why is there a handling charge (courier charge) as well as a
              shipping charge?
            </Accordion.Header>
            <Accordion.Body className="lead">
              Our staff of delivery drivers spends many hours a day driving and
              dealing with the traffic, because they have to drive to send the
              orders out from a safe place away from customs issues and avoid
              mail lifters, and this way you receive your order on time, as the
              way we send the orders out, it’s the most reliable way.
            </Accordion.Body>
          </Accordion.Item>
        </Accordion>
      </div>
  
    </>
  );
}

export default BasicExample;