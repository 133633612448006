import React from "react";
import { Spinner } from "react-bootstrap";
import Logo from "./assets/Logo-Dutyfree.png"
import '../components/loading.css'

function Loading() {
  return (
    <div className="loading-container">
      <img src={Logo} alt="" id="loading-logo"/>
      <div>
      <Spinner animation="grow" variant="primary" />
      </div>
    </div>
  );
}

export default Loading;





