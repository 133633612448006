import React from "react";
import { Link } from "react-router-dom";
import "./carrers.css";
function Carrers() {
  return (
    <div className="carrers-container">
      <div className="vacante">
        <Link to="/Vacantes">VENDEDORA DE MOSTRADOR</Link>
        <p>
          <span className="fw-bold">DutyFree Supply House </span>Distruibuidora
          de medicamentos. Busca vendedoras de mostrador para zonas turisticas
          de Quintana Roo
        </p>
        <p>Haz click en el enlace para postularte</p>
      </div>
      <div className="vacante">
        <Link to="/Vacantes">EJECUTIVA DE VENTAS</Link>
        <p>
          <span className="fw-bold">DutyFree Supply House </span>Distruibuidora
          de medicamentos. Busca Ejecutiva de ventas para zonas turisticas de
          Quintana Roo
        </p>
        <p>Haz click en el enlace para postularte</p>
      </div>
    </div>
  );
}

export default Carrers;
