
import React from "react";
import { useRef } from "react";
import { FaBars, FaTimes } from "react-icons/fa";
import "./navbar.css";
import Logo from "./assets/Logo-Dutyfree.png";
import { NavLink } from "react-router-dom";

function NavBar() {
  const navRef = useRef();

  const showNavbar = () => {
    navRef.current.classList.toggle("responsive-nav");
  };

  return (
    <header>
      <img className="logo" src={Logo} alt="logo-dutyfree" />

      <nav ref={navRef} className="navbar">
        <NavLink to="/">HOME</NavLink>
        <NavLink to="/Products">PRODUCTS</NavLink>

        <NavLink to="/About">ABOUT US</NavLink>
        <NavLink to="/FAQs">FAQs</NavLink>
        <NavLink to="/Contact">CONTACT-US</NavLink>

        <button className="nav-btn nav-close-btn" onClick={showNavbar}>
          <FaTimes />
        </button>
      </nav>


      <button className="nav-btn" onClick={showNavbar}>
        <FaBars />
      </button>
    </header>
  );
}

export default NavBar;
