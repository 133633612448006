import React from "react";
import "./FooterList.css";
import Icon from "./assets/icons8-medicina-64.png";
import { Link } from "react-router-dom";

const FooterList = () => {
  return (
    <div className="page-section-dutyfree-info">
      <div className="dutyfree-description">
        <img src={Icon} id="duty-free-hand" alt="icon" />
        <p>
          Welcome to <span className="fw-bold">Duty Free Supply House™ </span>
        </p>
        <p>
          
          We are committed on improving our offering in trying to be closer to
          our goal of offering the best value and exceptional service.
        </p>
        <p className="fw-bold">
          CUSTOMER SERVICE:
          <span> dutyfreesupplyhouse@gmail.com</span>
        </p>
      </div>

      <div className="company-list-container">
        <div className="menu">
          <p>Customer</p>
          <Link to="/FAQs">FAQs</Link>
          <Link>Support</Link>
          <Link to="/Contact">Contact Us</Link>
        </div>
        <div className="menu">
          <p>Company</p>
          <Link to="/About">About us</Link>
          <Link to="/MyAccount">My Account</Link>
          <Link to="/Careers">Careers</Link>
        </div>
      </div>
    </div>
  );
};

export default FooterList;
