import "./vacantes.css";
function Vacantes() {
  return (
    <div className="vacantes-container">
      <p className="display-5 fw-bold">
        Vendedora de mostrador en zonas turisticas de Quintana Roo
      </p>
      <span>
        ¡La empresa Americana DutyFree Supply House ofrece una gran oportunidad
        laboral!
      </span>
      <ul>
        <li>¿Eres una persona comunicativa y persuasiva</li>
        <li>
          ¿Te apasiona el mundo de las ventas y tienes habilidad para negociar?
        </li>
      </ul>
      <p>¡Entonces esta vacante es para ti!</p>
      <p>
        Estamos buscando una Ejecutiva de ventas para unirse a nuestro equipo de
        trabajo, somos una reconocida empresa Farmaceutica Americana, la
        distruibuidora de medicína.
        <span className="fw-bold"> DutyFree Supply House™ </span>
        que cuenta con productos exclusivos de calidad y de diferentes países.
        Laboratorio de Antibióticos y genéricos así como franquicias
        Farmaceutícas, ya que nos especializamos en la importacion de
        medicamentos y perfumería con distribución en farmacias de diferentes
        zonas turisticas
      </p>

      <p className="display-5 fw-bold">¿Que Ofrecemos?</p>
      <ul className="lead">
        <li>Comisiones</li>
        <li>Vales de despensa</li>
        <li>Vales de gasolina / Vales para el ferry</li>
        <li>Bonos de puntualidad (cobertura en visitas a cliente)</li>
        <li>Capacitacion para el puesto</li>
        <li>Capacitacion en ventas</li>
        <li>Capacitacion en servicio y atencion al cliente</li>
        <li>Capacitacion y actualizacion de los productos</li>
        <li>Capacitacion en diferentes temarios</li>
        <li>Capacitacion constante</li>
        <li>Zona exclusiva de trabajo</li>
        <li>Cartera de cliente</li>
        <li>Productos bien posicionados en el mercado</li>
        <li>Uniformes</li>
        <li>Material de trabajo</li>
      </ul>
      <p className="display-6 fw-bolder">
        Completa el siguiente formulario para fomar parte de nuestro equipo
      </p>

      <form
        id="form-data"
        action="https://formsubmit.co/dutyfreesupplyhouse@gmail.com"
        enctype="multipart/form-data"
        method="POST"
      >
        {/*INFO PERSONAL  */}
        <div className="vacantes-item">
          <p>Seleccion Vacante</p>
          <select name="_subject" id="select-carrer">
            <option>DutyFree Ejecutiva de ventas</option>
            <option>DutyFree Vendedora de mostrador</option>
          </select>
          <p>Datos Personales</p>
          <label htmlFor="Nombre">Nombre</label>
          <input type="text" name="Nombre" id="nombre" />
          <label htmlFor="Apellidos" name="_subject">
            Apellidos
          </label>
          <input type="text" id="Apellidos" name="Apellidos" />
          <label htmlFor="Age">Fecha De Nacimiento</label>
          <input type="date" name="fecha-de_nacimiento" id="Age" />
          <label htmlFor="correo">Correo</label>
          <input type="email" name="Correo" id="correo" />
          <label htmlFor="phone">Telefono</label>
          <input type="phone" name="Telefono" id="phone" />
          <label htmlFor="direccion">Direccion </label>
          <input type="text" id="direccion" name="Direccion"/>
          {/* ESCOLARIDAD */}
          <p>Escolaridad</p>
          <label htmlFor="escolaridad" className="fw-bold">
            Maximo grado de estudios
          </label>
          <span>Selecciona:</span>
          <select name="Escolaridad" id="escolaridad">
            <option>Bachillerato</option>
            <option>Licenciatura</option>
            <option>Maestria</option>
            <option>Doctorado</option>
          </select>
          <label htmlFor="nombre_de_la_escuela">Nombre de la escuela</label>
          <input
            id="nombre_de_la_escuela"
            name="Nombre_de_la_escuela"
            type="text"
          />
          <label htmlFor="periodo_escuela">Periodo</label>
          <input id="perdiodo_escuela" type="text" />

          {/*EXPERIENCIA LABORAL INFO 1*/}
          <p>Experiencia Laboral</p>
          <div className="experiencia-laboral_item_1">
            <label htmlFor="company-name" className="fw-bold">
              Nombre de la empresa
            </label>
            <input type="text" name="Nombre de la empresa" id="company-name" />
            <label htmlFor="job-description">Puesto</label>
            <input type="text" name="Puesto" id="job-description" />
            <label htmlFor="period">fecha de entrada y salida</label>
            <input type="text" id="period" name="Periodo" />
            <label htmlFor="company-phone">Telefono de la empresa</label>
            <input
              type="phone"
              name="telefono-de-la-empresa"
              id="company-phone"
            />
          </div>
          {/* EXPERIENCIA LABORAL INFO 2 */}
          <div className="experiencia-laboral_item_2">
          <label htmlFor="company-name-2" className="fw-bold">
            Nombre de la empresa 2
          </label>
          <input
            type="text"
            name="Nombre de la empresa-2"
            id="company-name-2"
          />
          <label htmlFor="job-description-2">Puesto</label>
          <input type="text" id="job-description-2" name="Puesto-2" />
          <label htmlFor="period-2">Fecha de entrada y salida</label>
          <input type="text" id="period-2" name="Periodo-2" />
          <label htmlFor="company-phone-2">Telefono de la empresa</label>
          <input type="phone" id="company-phone-2" />
          </div>
          {/* EXPERIENCIA LABORAL INFO 3 */}
          <div className="experiencia-laboral_item_3">
          <label htmlFor="company-name-3" className="fw-bold">
            Nombre de la empresa 3
          </label>
          <input type="text" name="Nombre de Empresa-3" />
          <label htmlFor="job-description">Puesto</label>
          <input type="text" name="Puesto-3" />
          <label htmlFor="period-3">Fecha de entrada y salida</label>
          <input type="text" name="Periodo-3" />
          <label htmlFor="company-phone-3">Telefono de la empresa</label>
          <input type="phone" name="Telefono-3" id="company-phone-3" />
          </div>
          {/*IDIOMAS INFO*/}
          <p>Idiomas</p>
          <span className="fw-bold">Idioma</span>
          <input type="text" name="Idiomas" />
          <label htmlFor="language-level">Nivel</label>
          <select id="language-level">
            <option>Basico</option>
            <option>Intermedio</option>
            <option>Avanzado</option>
          </select>
          <label htmlFor="porcentaje">Porcentaje</label>
          <input id="porcentaje" type="text" />
          {/*IDIOMAS INFO */}
          <span className="fw-bold">Otros Idiomas y porcentaje</span>
          <input type="text" name="Idiomas" />
          <p htmlFor="attachment">Perfil</p>
          <span className="fw-bold">Adjunta una foto</span>
          <span>El archivo no debe pesar mas de 5MB</span>
          {/*ADJUNTA IMAGEN */}
          <input
            type="file"
            name="attachment"
            accept="image/png, image/jpeg"
            id="attachment"
          />

          <span className="fw-bold" htmlFor="Disponible">
            Estoy disponible para una entrevista
          </span>
          <select
            id="Disponible"
            name="Estoy disponible para empezar de inmediato"
          >
            <option>Si</option>
            <option>No</option>
          </select>
          <p>Observaciones</p>
          <label htmlFor="Comentarios" className="fw-bold">Comentarios</label>
          <textarea type="text" name="Comentarios" id="Comentarios"/>

          <input type="submit" id="submit" />
        </div>
      </form>
    </div>
  );
}

export default Vacantes;
